import React from 'react';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import {
    addNotification,
    deleteNotification,
    deleteNotifications,
    updateLastRead,
    updateReadNotifications,
} from 'actions/Notifications';

import IntlMessages from 'util/IntlMessages';

import showNotificationMessage from '../../../util/NotificationMessage';
import { openLink } from '../../../util/openLink';
import { isTokenValid } from '../../../util/tokenValidation';

import AppNotification from './AppNotification/AppNotification';
import AppNotificationModal from './AppNotificationModal/AppNotificationModal';
import CardHeader from './CardHeader/CardHeader';
import NotificationAPI from './NotificationAPI';

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.NotificationAPI = new NotificationAPI();

        this.state = {
            isNotificationModalOpen: false,
            isNotificationsLoading: false,
            appNotification: false,
        };
    }

    componentDidMount = () => {
        if (this.props.location.hash) {
            openLink(this.props.location.pathname, { ctrlKey: false });
        }
    };

    updateNotifications = async () => {
        try {
            const response = await this.NotificationAPI.updateLastReadDate();

            if (!isTokenValid(response)) return;

            this.props.updateLastRead(new Date());
        } catch (error) {
            this.handleError(error);
        }
    };

    clickNotification = async (id, url) => {
        this.props.updateReadNotifications(id);
        try {
            const response = await this.NotificationAPI.readNotification(id);

            if (!isTokenValid(response)) return;
        } catch (error) {
            this.handleError(error);
        } finally {
            const win = window.open(url, '_blank');
            win.focus();
        }
    };

    deleteNotification = async (id) => {
        this.props.deleteNotification(id);

        try {
            const response = await this.NotificationAPI.deleteNotification(id);

            if (!isTokenValid(response)) return;
        } catch (error) {
            this.handleError(error);
        }
    };

    deleteNotifications = async () => {
        this.props.deleteNotifications();

        try {
            const response = await this.NotificationAPI.deleteNotifications();

            if (!isTokenValid(response)) return;
        } catch (error) {
            this.handleError(error);
        }
    };

    isNotificationAlertOn = () => {
        return !this.props.userNotifications.notifications.every(
            (notification) =>
                moment(notification.notification.createdAt).isBefore(
                    this.props.userNotifications.notificationsLastReadDate
                )
        );
    };

    closeModal = () => {
        this.setState({ isNotificationModalOpen: false });
        openLink(`${this.props.location.pathname}`, { ctrlKey: false });
    };

    openModal = () => {
        this.setState({ isNotificationModalOpen: true });
        openLink(`${this.props.location.pathname}#notifications`, {
            ctrlKey: false,
        });
    };

    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification,
        });
    };

    handleError = (error) => {
        const errorMessage = (
            <>
                <p>{error.message}</p>
                <IntlMessages id='notification.error.unknown' />
            </>
        );
        showNotificationMessage('error', errorMessage);
    };

    render() {
        const isMobile = window.innerWidth < 450;

        const { isNotificationModalOpen, isNotificationsLoading } = this.state;

        const notifications = this.props.userNotifications
            ? this.props.userNotifications.notifications
            : [];

        const numberOfNotifications =
            (this.props.userNotifications &&
                this.props.userNotifications.totalNotifications) ||
            0;

        const alertIcon = (
            <IconButton
                onClick={() => {
                    if (isMobile) {
                        this.openModal();
                    }
                    this.updateNotifications();
                }}
                className={`icon-btn ${isMobile ? 'text-white' : ''}`}
            >
                <Badge
                    badgeContent={numberOfNotifications}
                    invisible={!this.isNotificationAlertOn()}
                    color='primary'
                    overlap='rectangular'
                >
                    <NotificationsNoneIcon />
                </Badge>
            </IconButton>
        );

        return (
            <>
                {!isNotificationsLoading && (
                    <AppNotificationModal
                        notifications={notifications}
                        onDelete={() => this.deleteNotifications()}
                        isNotificationModalOpen={isNotificationModalOpen}
                        onClose={this.closeModal}
                        location={this.props.location}
                        deleteNotification={this.deleteNotification}
                        clickNotification={this.clickNotification}
                        numberOfNotifications={numberOfNotifications}
                    />
                )}

                <ul className='header-notifications list-inline my-auto'>
                    {!isMobile
                        ? !isNotificationsLoading && (
                              <li className='list-inline-item app-tour'>
                                  <Dropdown
                                      className='quick-menu'
                                      isOpen={this.state.appNotification}
                                      toggle={this.onAppNotificationSelect}
                                  >
                                      <DropdownToggle
                                          className='d-inline-block'
                                          tag='span'
                                          data-toggle='dropdown'
                                      >
                                          {alertIcon}
                                      </DropdownToggle>

                                      <DropdownMenu right>
                                          <CardHeader
                                              styleName='jr-card-header'
                                              noOfNotifications={
                                                  numberOfNotifications
                                              }
                                              onDelete={() =>
                                                  this.deleteNotifications()
                                              }
                                              showDeleteIcon
                                              heading={
                                                  <IntlMessages id='appNotification.title' />
                                              }
                                          />
                                          <AppNotification
                                              notifications={notifications}
                                              numberOfNotifications={
                                                  numberOfNotifications
                                              }
                                              deleteNotification={
                                                  this.deleteNotification
                                              }
                                              clickNotification={
                                                  this.clickNotification
                                              }
                                          />
                                      </DropdownMenu>
                                  </Dropdown>
                              </li>
                          )
                        : !isNotificationsLoading && (
                              <li className='list-inline-item app-tour'>
                                  <Dropdown className='quick-menu'>
                                      {alertIcon}
                                  </Dropdown>
                              </li>
                          )}
                </ul>
            </>
        );
    }
}

const mapStateToProps = ({ notifications }) => {
    const { userNotifications } = notifications;
    return {
        userNotifications,
    };
};

export default connect(mapStateToProps, {
    addNotification,
    updateLastRead,
    updateReadNotifications,
    deleteNotification,
    deleteNotifications,
})(withRouter(Notifications));
