import axiosInstance from '../shared/api/axiosInstance';

const ActionsLogsAPI = {
    getLogs: (
        company,
        factory,
        startDate,
        endDate,
        searchInput,
        entityType,
        pageNumber,
        paginationLimit
    ) =>
        entityType === 'all'
            ? axiosInstance.post(`${company}/${factory}/usersLogs`, {
                  startDate,
                  endDate,
                  searchInput,
                  pageNumber,
                  paginationLimit,
              })
            : axiosInstance.post(`${company}/${factory}/usersLogs`, {
                  startDate,
                  endDate,
                  searchInput,
                  entityType,
                  pageNumber,
                  paginationLimit,
              }),

    getTagCurrentData: (company, factory, rfid) =>
        axiosInstance.get(
            `${company}/${factory}/usersLogs/getTagCurrentData/${rfid}`
        ),
};

export default ActionsLogsAPI;
