import {
    GET_NOTIFICATIONS_SUCCESS,
    ADD_NOTIFICATION,
    UPDATE_LAST_READ,
    UPDATE_READ_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATIONS,
} from 'constants/ActionTypes';

const initialSettings = {
    userNotifications: {
        notifications: [],
        totalNotifications: 0,
    },
    slice: 20,
};

export default (state = initialSettings, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                userNotifications: action.payload,
                slice: state.slice + 20,
            };
        }
        case ADD_NOTIFICATION: {
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    totalNotifications:
                        state.userNotifications.totalNotifications + 1,
                    notifications: [
                        action.payload,
                        ...state.userNotifications.notifications,
                    ],
                },
            };
        }
        case DELETE_NOTIFICATIONS: {
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    notifications: [],
                    totalNotifications: 0,
                },
            };
        }
        case UPDATE_LAST_READ: {
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    notificationsLastReadDate: action.payload,
                },
            };
        }
        case UPDATE_READ_NOTIFICATIONS: {
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    notifications: state.userNotifications.notifications.map(
                        (notification) =>
                            notification.notification._id === action.payload
                                ? { ...notification, isRead: true }
                                : notification
                    ),
                },
            };
        }
        case DELETE_NOTIFICATION: {
            return {
                ...state,
                userNotifications: {
                    ...state.userNotifications,
                    notifications: state.userNotifications.notifications.filter(
                        (notification) =>
                            notification.notification._id !== action.payload
                    ),
                    totalNotifications:
                        state.userNotifications.totalNotifications - 1,
                },
            };
        }
        default:
            return state;
    }
};
