import { SET_USER_PERMISSION } from 'constants/ActionTypes';
import { userSignOut } from '../../../../actions';
import {
    setCompanyAQL,
    setCompanyBatchGrouping,
    setCompanyCutting,
    setCompanyIdleTime,
    setFactoryAQL,
    setFactoryAutomateBeats,
    setFactoryBatchGrouping,
    setFactoryCutting,
    setFactoryIdleTime,
} from '../../../../reducers/Settings';
import { store } from '../../../../store';
import { GET_COMPANY } from '../../devtools/companyUrlSwitcher/utils';
import AuthAPI from '../api/AuthAPI';
import PermissionAPI from '../api/PermissionAPI';
import getTranslation from './Language';
import notificationMessage from './NotificationMessage';
import getUserPermissions from './Permissions';
import { handlePromiseError } from 'app/modules/shared/api/apiRequest';

let canUpdateUserData = true;

const updateUserFactory = async () => {
    const authAPI = new AuthAPI();
    const userId = JSON.parse(localStorage.getItem('user_id'));

    const company =
        import.meta.env.MODE === 'development' ||
        import.meta.env.VITE_ON_PREMISE === 'true'
            ? GET_COMPANY()
            : userId.company;

    const factoryResponse = await authAPI.getOneFactory(userId.token, company);

    const factoryPayload = factoryResponse.data;
    if (factoryPayload.status) {
        userId.company = company;
        userId.factory = Number(
            factoryPayload.factories.at(0).factory_company_id
        );
        userId.factoryData = factoryPayload.factories.at(0);
        userId.companyData = factoryPayload.company;

        store.dispatch(setFactoryIdleTime(userId.factoryData.idleTime));
        store.dispatch(setCompanyIdleTime(userId.companyData.idleTime));

        store.dispatch(
            setFactoryBatchGrouping(userId.factoryData.batchGrouping)
        );
        store.dispatch(
            setCompanyBatchGrouping(userId.companyData.batchGrouping)
        );

        const isAQLFactoryEnabled = userId.factoryData.isAQLEnabled;
        store.dispatch(
            setFactoryAQL({
                isAQLEnabled: isAQLFactoryEnabled,
                ...(isAQLFactoryEnabled
                    ? {
                          generalInspectionLevel:
                              userId.factoryData.generalInspectionLevel,
                          majorDefectsAQL: userId.factoryData.majorDefectsAQL,
                          minorDefectsAQL: userId.factoryData.minorDefectsAQL,
                      }
                    : {}),
            })
        );
        store.dispatch(setCompanyAQL(userId.companyData.isAQLEnabled));

        store.dispatch(setFactoryCutting(userId.factoryData.isCuttingEnabled));
        store.dispatch(
            setFactoryAutomateBeats(userId.factoryData.isAutomateBeats)
        );
        store.dispatch(setCompanyCutting(userId.companyData.isCuttingEnabled));

        userId.factoryHasSections = factoryPayload.company.has_sections;
        localStorage.setItem('user_id', JSON.stringify(userId));
    }
};

export const isTokenValid = (response, refresh = false) => {
    const isInValidToken = response.data.force_logout;

    if (response.data.updateUserData) {
        if (canUpdateUserData) {
            canUpdateUserData = false;
            PermissionAPI.getUserPermissions()
                .then((response) => {
                    if (!response.data.user) return false;

                    const permissions = getUserPermissions(
                        true,
                        response.data.user
                    );
                    const token = response.data.token;
                    const scope = response.data.user.scope;
                    const stations = response.data.user.stations;

                    const from = window.location.pathname;

                    const homepage = response.data.user.roles[0].home_page;

                    store.dispatch({
                        type: SET_USER_PERMISSION,
                        payload: {
                            permissions,
                            stations,
                            token,
                            from,
                            homepage,
                            scope,
                        },
                    });

                    updateUserFactory()
                        .then(() => {
                            setTimeout(() => {
                                canUpdateUserData = true;
                            }, 2500);

                            if (refresh)
                                setTimeout(
                                    () => window.location.reload(true),
                                    4000
                                );
                        })
                        .catch(handlePromiseError);
                })
                .catch(handlePromiseError);
        }
        return false;
    }

    if (!isInValidToken) return true;

    store.dispatch(userSignOut());
    notificationMessage('error', getTranslation('shared.error.inValidToken'));

    return false;
};
