const SET_PRODUCTION_ORDERS = 'SET_PRODUCTION_ORDERS';

const initialState = {
    productionOrders: null,
};

export const productionOrdersSelector = (state) =>
    state.productionOrders.productionOrders;

export const setProductionOrders = (orders) => ({
    type: SET_PRODUCTION_ORDERS,
    payload: orders,
});

export const ProductionOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTION_ORDERS: {
            return { ...state, productionOrders: action.payload };
        }
        default:
            return state;
    }
};
