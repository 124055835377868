import { store } from '../../../../store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryBatchGroupingEnabled = () => {
    try {
        return !!JSON.parse(localStorage.user_id).factoryData.batchGrouping;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const companyBatchGrouping = () => {
    try {
        return !!JSON.parse(localStorage.user_id).companyData.batchGrouping;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const batchGroupingEnabled = () => {
    try {
        const state = store.getState();
        return (
            state.settings.companyBatchGrouping &&
            state.settings.factoryBatchGrouping
        );
    } catch (e) {
        return false;
    }
};

/** @returns {boolean}  */
export const useHasBatchGrouping = () =>
    useSelector(({ settings }) => {
        return settings.factoryBatchGrouping && settings.companyBatchGrouping;
    });
