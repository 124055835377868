import React, { useState } from 'react';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagDetails from './TagDetails';
import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle,
} from '@material-ui/core';
import Scanner from '../Scanner/Scanner';
import ScanningImage from '../ScanningImage/ScanningImage';
import IntlMessages from 'util/IntlMessages';

const WhatThisTag = () => {
    const [isScannerModalOpen, setIsScannerModalOpen] = useState(false);
    const [tagId, setTagId] = useState();

    const handleScanTag = (tag) => {
        tag && setTagId(tag);
    };
    const closeHandler = () => {
        setIsScannerModalOpen(false);
    };

    return (
        <>
            <IconButton
                className='p-2'
                size='small'
                onClick={() => setIsScannerModalOpen(true)}
            >
                <FontAwesomeIcon icon={faKeyboard} color='white' />
            </IconButton>
            {isScannerModalOpen && (
                <Dialog
                    className='text-center'
                    aria-labelledby='form-dialog-title'
                    maxWidth={'sm'}
                    fullWidth={true}
                    open={true}
                    onClose={closeHandler}
                >
                    <DialogTitle id='form-dialog-title'>
                        {<IntlMessages id={'shared.whatThisTagModal.title'} />}
                    </DialogTitle>
                    <DialogContent className='text-center'>
                        <Scanner
                            handleScan={(tag) => {
                                handleScanTag(tag);
                            }}
                            scan={true}
                        >
                            <ScanningImage />
                        </Scanner>
                    </DialogContent>
                    <DialogActions className='d-flex justify-content-center p-3'>
                        {tagId && <TagDetails tagId={tagId} />}
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default WhatThisTag;
