import { range as lodashRange } from 'lodash-es';
import { createSelector } from 'reselect';

const SET_BATCH_GROUPS = 'SET_BATCH_GROUPS';
const SET_PRINTING_ROWS = 'SET_PRINTING_ROWS';
const SET_PRINTING_ROW = 'SET_PRINTING_ROW';

const initialState = {
    batchGroups: new Map(),
    printingRows: [],
};

export const batchGroupsSelector = (state) => state.batchGroups.batchGroups;
export const printingRowsSelector = (state) => state.batchGroups.printingRows;

const range = lodashRange;
export const allSelected = createSelector(
    batchGroupsSelector,
    printingRowsSelector,
    (batches, printingRows) => {
        const batchNums = [...batches.keys()];
        const printNums = new Set(
            printingRows.flatMap(({ from, to }) => range(from, to + 1)),
        );

        return batchNums.filter((num) => !printNums.has(num)).length === 0;
    },
);

export const setBatchGroups = (batchGroups) => ({
    type: SET_BATCH_GROUPS,
    payload: batchGroups,
});

export const setPrintingRow = (name, value, hasErrors, id) => ({
    type: SET_PRINTING_ROW,
    payload: { name, value, hasErrors, id },
});

export const setPrintingRows = (printingRows) => ({
    type: SET_PRINTING_ROWS,
    payload: printingRows,
});

export const BatchGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BATCH_GROUPS: {
            return { ...state, batchGroups: action.payload };
        }
        case SET_PRINTING_ROWS:
            return { ...state, printingRows: action.payload };
        case SET_PRINTING_ROW: {
            const { name, value, hasErrors, id } = action.payload;
            const newRows = state.printingRows.map((row) => {
                if (row.id === id) {
                    return {
                        ...row,
                        [name]: value,
                        hasErrors,
                    };
                } else return row;
            });

            return {
                ...state,
                printingRows: newRows,
            };
        }
        default:
            return state;
    }
};
