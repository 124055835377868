import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import {
    setDrawerType,
    switchLanguage,
    toggleChatComponent,
    toggleCollapsedNav,
} from 'actions/Setting';
import Menu from 'components/TopNav/Menu';
import {
    BELOW_THE_HEADER,
    // COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER,
    MINI_DRAWER,
} from 'constants/ActionTypes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Notifications from './Notifications/Notifications';
import { OfflineInlineInspections } from './OfflineInlineInspections';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { openLink } from '../../util/openLink';
import styles from './Header.module.scss';
import WhatThisTag from './WhatThisTag';
import garmentLogoMini from 'assets/images/garment-logo-miniold.png';

class Header extends React.Component {
    constructor(props) {
        super(props);

        const pathname = props.location.pathname.split('/');
        this.state = {
            anchorEl: undefined,
            drawerType: props.drawerType,
            chatOpenned: props.chatOpenned,
            factory: pathname[1],
        };

        this.mq = window.matchMedia('(max-width : 768px)');
    }

    onToggleCollapsedNav = () => {
        const val = !this.props.navCollapsed;
        this.setState(
            (prevState) => {
                return {
                    drawerType:
                        prevState.drawerType === FIXED_DRAWER
                            ? MINI_DRAWER
                            : FIXED_DRAWER,
                };
            },
            () => {
                this.props.setDrawerType(this.state.drawerType);
                this.props.toggleCollapsedNav(val);
            }
        );
    };
    onToggleChat = (e) => {
        const val = !this.props.chatOpenned;
        this.props.toggleChatComponent(val);
    };

    render() {
        const {
            navigationStyle,
            horizontalNavPosition,
            showWipReport,
            sectionId,
            showOfflineInlineInspections,
            removeStickyHeader,
        } = this.props;
        const { factory } = this.state;

        return (
            <AppBar
                className={`app-main-header ${
                    navigationStyle === HORIZONTAL_NAVIGATION &&
                    horizontalNavPosition === BELOW_THE_HEADER
                        ? 'app-main-header-top'
                        : ''
                } ${removeStickyHeader ? styles.nonStickyHeader : ''}`}
            >
                <Toolbar className='app-toolbar px-2' disableGutters={false}>
                    <IconButton
                        className={`jr-menu-icon mr-3 d-block ${styles.iconBtn}`}
                        aria-label='Menu'
                        onClick={this.onToggleCollapsedNav}
                    >
                        <span className={!this.mq.matches ? 'menu-icon' : ''}>
                            {this.mq.matches && (
                                <img
                                    src={garmentLogoMini}
                                    alt='Company Logo'
                                    width='40px'
                                    height='32px'
                                />
                            )}
                        </span>
                    </IconButton>

                    {this.props.children}

                    {navigationStyle === HORIZONTAL_NAVIGATION &&
                        horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

                    <div className='d-flex ml-auto'>
                        {showOfflineInlineInspections && (
                            <OfflineInlineInspections />
                        )}
                        {showWipReport && (
                            <IconButton
                                id='wipReport'
                                className='p-2'
                                size='small'
                            >
                                <FontAwesomeIcon
                                    icon={faLayerGroup}
                                    color='white'
                                    onClick={() =>
                                        openLink(
                                            `/${factory}/wipReport?sectionId=${sectionId}`,
                                            { ctrlKey: false }
                                        )
                                    }
                                />
                            </IconButton>
                        )}

                        <Notifications />
                        <WhatThisTag />

                        <IconButton
                            id='customIntercom'
                            className='p-2'
                            size='small'
                            onClick={this.onToggleChat}
                        >
                            <FontAwesomeIcon
                                icon={faCommentDots}
                                color='white'
                            />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = ({ settings, notifications }) => {
    const {
        drawerType,
        locale,
        navigationStyle,
        horizontalNavPosition,
        chatOpenned,
    } = settings;
    const { userNotifications } = notifications;
    return {
        drawerType,
        locale,
        navigationStyle,
        horizontalNavPosition,
        userNotifications,
        chatOpenned,
    };
};

export default withRouter(
    connect(mapStateToProps, {
        toggleCollapsedNav,
        toggleChatComponent,
        setDrawerType,
        switchLanguage,
    })(Header)
);
