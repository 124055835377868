import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import React from 'react';

const CardHeader = (props) => {
    const {
        heading,
        noOfNotifications,
        showCloseIcon,
        styleName,
        showDeleteIcon,
        onDelete,
    } = props;
    return (
        <div className={`d-flex justify-content-between ${styleName}`}>
            <div>
                <h3 className='m-0'>
                    {heading} ({noOfNotifications})
                </h3>
            </div>
            <div>
                {showDeleteIcon && (
                    <IconButton
                        color='inherit'
                        onClick={onDelete}
                        aria-label='Close'
                    >
                        <DeleteSweepIcon />
                    </IconButton>
                )}
                {showCloseIcon && (
                    <IconButton
                        color='inherit'
                        onClick={props.onClose}
                        aria-label='Close'
                    >
                        <CloseIcon />
                    </IconButton>
                )}
            </div>
        </div>
    );
};

CardHeader.defaultProps = {
    styleName: '',
};

export default CardHeader;
