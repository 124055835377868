import { store } from '../../../../store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryAQLEnabled = () => {
    try {
        return JSON.parse(localStorage.user_id)?.factoryData;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const companyAQL = () => {
    try {
        return !!JSON.parse(localStorage.user_id).companyData.isAQLEnabled;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const AQLEnabled = () => {
    try {
        const state = store.getState();
        return (
            state.settings.companyAQL && state.settings.factoryAQL.isAQLEnabled
        );
    } catch (e) {
        return false;
    }
};

/** @returns {boolean}  */
export const useHasAQL = () =>
    useSelector(({ settings }) => {
        return settings.factoryAQL.isAQLEnabled && settings.companyAQL;
    });
