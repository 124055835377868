import React, { Component } from 'react';

import * as Sentry from '@sentry/react';
import { IS_DEPLOYMENT } from 'index';
import getTranslation from '../util/Language';

export class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        if (IS_DEPLOYMENT) {
            Sentry.captureException(error, { extra: errorInfo });
        }
        console.error('Error caught by Error Boundary:', error, errorInfo);
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>{getTranslation('notification.error.unknown')}</h1>
                    {!IS_DEPLOYMENT &&
                        this.state.error &&
                        this.state.errorInfo && (
                            <code
                                dir='ltr'
                                style={{
                                    whiteSpace: 'pre-wrap',
                                    marginInline: '20px',
                                    textAlign: 'left',
                                }}
                            >
                                {this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </code>
                        )}
                </div>
            );
        }

        return this.props.children;
    }
}
