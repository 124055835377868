import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CompanyUrlSwitcher } from './companyUrlSwitcher';

const DevtoolCheckbox = () => {
    const [hideDevTool, setHideDevTool] = useState(false);

    const handleChange = (e) => {
        setHideDevTool(e.target.checked);
    };

    return (
        <div>
            {window.location.pathname === '/signin' && (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hideDevTool}
                            onChange={(e) => handleChange(e)}
                            name='hideDevTool'
                            color='primary'
                        />
                    }
                    label='Hide Developer Tool'
                    className='position-fixed'
                    style={{ right: '0' }}
                />
            )}

            <CompanyUrlSwitcher hideDevTool={hideDevTool} />
        </div>
    );
};

export default DevtoolCheckbox;
