import axiosInstance from '../../../api/axiosInstance';

class NotificationAPI {
    updateLastReadDate = () => axiosInstance.put('notification/updateLastRead');

    deleteNotification = (notficationId) =>
        axiosInstance.delete(
            `notification/deleteNotification/${notficationId}`
        );
    deleteNotifications = () =>
        axiosInstance.delete('notification/deleteNotifications');

    readNotification = (notificationId) =>
        axiosInstance.put(`notification/readNotification/${notificationId}`);
}

export default NotificationAPI;
