import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { userSignOut } from 'actions/Auth';
import React from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';

class LogoutDialog extends React.Component {
    state = {
        name: '',
        open: this.props.isOpen,
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeDialog();
    };

    handleLogout = () => {
        if (this.props.handleConfirmation) {
            this.props.handleConfirmation();
        }
        this.props.userSignOut();
    };

    render() {
        return (
            <div>
                <span />
                <Dialog
                    aria-labelledby='form-dialog-title'
                    onClose={this.handleClose}
                    open={this.state.open}
                >
                    <DialogTitle id='form-dialog-title'>
                        <IntlMessages id='shared.logoutMessage' />
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            variant='contained'
                            color='default'
                            onClick={this.handleClose}
                        >
                            <IntlMessages id='button.cancel' />
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.handleLogout}
                        >
                            <IntlMessages id='popup.logout' />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(null, { userSignOut })(LogoutDialog);
