import he from 'he';

const decodeStringTraverse = (object) => {
    Object.keys(object ?? {}).forEach((key) => {
        const value = object[key];
        const type = typeof value;

        if (type === 'string') {
            object[key] = he.decode(value);
            return;
        }

        if (type === 'object' && value !== null) {
            decodeStringTraverse(value);
        }
    });
};

export const onFulfilled = (response) => {
    if (response.config.decodeStrings === false) {
        return response;
    }

    if (typeof response.data === 'object') {
        decodeStringTraverse(response.data);
    }

    return response;
};

export const onRejected = (error) => {
    return Promise.reject(error);
};
