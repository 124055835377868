import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { memoize } from 'lodash-es';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import styles from './IntlMessages.module.scss';

export const getTooltip = memoize(key => {
    const userLang =
        localStorage.getItem('lang') &&
        JSON.parse(localStorage.getItem('lang'));

    const translations =
        localStorage.getItem('translations') &&
        JSON.parse(localStorage.getItem('translations'));

    if (!userLang || !translations) return null;

    const tooltipLang = translations[`${userLang.locale}Tooltip`];
    return tooltipLang ? tooltipLang[key] : null;
});


const InjectMassage = props => {
    const { id } = props;
    const tooltipMessage = getTooltip(id);

    if (tooltipMessage) {
        return (
            <Tooltip
                id={id}
                title={tooltipMessage}
                classes={{ tooltip: styles.tooltip }}
                interactive
                arrow
            >
                <span>
                    <FormattedMessage {...props} />
                    <FontAwesomeIcon
                        className='mx-2'
                        icon={faInfoCircle}
                        style={{ fontSize: '.8em' }}
                    />
                </span>
            </Tooltip>
        );
    }

    return <FormattedMessage {...props}/>;
};

InjectMassage.defaultProps = {
  id: 'translation.missing',
};

export default React.memo(injectIntl(InjectMassage, { withRef: false }));
