import Chip from '@material-ui/core/Chip';
import { hexToRgb } from 'app/modules/shared/util/hexToRgb';
import React from 'react';
import style from './SectionTag.module.scss';

const setTagColor = (color, selected) => ({
    color,
    backgroundColor: selected ? hexToRgb(color, 0.2) : '#fff',
    border: `1px solid ${selected ? 'transparent' : color}`,
});

export const SectionTag = ({ tag, selected, clickable = true }) => {
    if (!tag) {
        return null;
    }
    return (
        <Chip
            clickable={clickable}
            label={tag.name}
            size='small'
            className={style.sectionTag}
            style={setTagColor(tag.color, selected)}
        />
    );
};
