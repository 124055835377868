import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import ActionsLogsAPI from 'app/modules/ActionsLogs/ActionsLogsAPI';
import getTranslation from '../../util/Language';
import { Spinner } from '../Spinner/Spinner';
import { apiRequest } from '../../api/apiRequest';
import { useAuthuser } from '../../hooks/useAuthuser';
import { EntityLabel } from '../EntityLabel/EntityLabel';
import { openLink } from '../../util/openLink';
import { GarmentLink } from '../GarmentLink';
import { UserModalContent } from 'app/modules/ActionsLogs/Components/UserModal';
import { useScope } from '../../hooks/useScope';

const TagDetails = ({ tagId }) => {
    const [tagData, setTagData] = useState();
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isTagFound, setIsTagFound] = useState(false);
    const { company, factory, permissions } = useAuthuser();

    const hasEmployeeEffciencyReportAccess = permissions.find(
        (p) => p.name === 'employee_history_access'
    );

    const { sections } = useScope('employeesEfficiency');

    useEffect(() => {
        setTagData();
        setIsLoadingData(true);
        apiRequest(ActionsLogsAPI.getTagCurrentData, company, factory, tagId)
            .then(({ data }) => {
                setTagData(data.data);
                setIsTagFound(true);
                setIsLoadingData(false);
            })
            .catch(() => {
                setIsLoadingData(false);
                setIsTagFound(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagId]);

    let body = <div />;
    let title = <div />;

    const isSupervisorPage = window.location.pathname.startsWith('/s/', 2);

    if (tagData && tagData.table) {
        switch (tagData.table) {
            case 'batch':
                body = (
                    <EntityLabel
                        type='batch'
                        data={{
                            clientName: tagData.clientName,
                            orderNumber: tagData.productionOrderNumber,
                            styleName: tagData.styleName,
                            styleCode: tagData.styleCode,
                            cuttingOrderNumber: tagData.cuttingOrderNumber,
                            number: tagData.batchNumber,
                            color: tagData.colorName,
                            size: tagData.sizeName,
                            quantity: tagData.quantity,
                            serial: {
                                from: tagData.from,
                                to: tagData.to,
                            },
                        }}
                        style={{
                            cursor: 'pointer',
                            margin: '20px auto',
                            float: 'none',
                        }}
                        onClick={(event) =>
                            openLink(
                                `${
                                    isSupervisorPage
                                        ? 'batches_history'
                                        : `/${factory}/s/batches_history`
                                }/${tagData.productionOrderId}/${
                                    tagData.batchId
                                }${
                                    tagData.sectionId
                                        ? '?sectionId=' + tagData.sectionId
                                        : ''
                                }`,
                                event
                            )
                        }
                    />
                );
                break;

            case 'batchGroup':
                body = (
                    <EntityLabel
                        type='batchGroup'
                        data={{
                            clientName: tagData.clientName,
                            orderNumber: tagData.productionOrderNumber,
                            styleName: tagData.styleName,
                            styleCode: tagData.styleCode,
                            cuttingOrderNumber:
                                tagData.cuttingOrderNumber.length > 3
                                    ? getTranslation('shared.multiple')
                                    : tagData.cuttingOrderNumber.join(', '),
                            number: tagData.batchGroupNumber,
                            color:
                                tagData.colorName === 'multiple'
                                    ? getTranslation('shared.multiple')
                                    : tagData.colorName,
                            size:
                                tagData.sizeName.length > 3
                                    ? getTranslation('shared.multiple')
                                    : tagData.sizeName.join(', '),
                            quantity: tagData.quantity,
                            batches: tagData.batches || [],
                            serial:
                                tagData.from === 'separated' ||
                                tagData.to === 'separated'
                                    ? getTranslation(
                                          'batch.batchGroup.interruptedSerial'
                                      )
                                    : {
                                          from: tagData.from
                                              ? tagData.from
                                              : ' ',
                                          to: tagData.to ? tagData.to : ' ',
                                      },
                        }}
                        style={{
                            cursor: 'pointer',
                            margin: '20px auto',
                            float: 'none',
                        }}
                        onClick={(event) =>
                            openLink(
                                `${
                                    isSupervisorPage
                                        ? 'batchGroups'
                                        : `/${factory}/s/batchGroups`
                                }/${tagData.productionOrderId}/${
                                    tagData.batchGroupId
                                }${
                                    tagData.sectionId
                                        ? '?sectionId=' + tagData.sectionId
                                        : ''
                                }`,
                                event
                            )
                        }
                    />
                );
                break;

            case 'user':
                body = (
                    <div className='mt-4'>
                        <div className='mb-2'>
                            <strong>
                                <IntlMessages id={'shared.name'} />:{' '}
                            </strong>
                            <span>
                                {tagData.first_name + ' ' + tagData.last_name}
                            </span>
                        </div>
                        <UserModalContent data={tagData} />
                    </div>
                );
                break;

            case 'employee':
            default:
                body = hasEmployeeEffciencyReportAccess ? (
                    <GarmentLink
                        to={`employee_history/${
                            tagData.employeeId
                        }/?sectionId=${sections?.join(',')}`}
                        style={{
                            display: 'block',
                            textAlign: 'center',
                            color: 'blue',
                            fontSize: '1rem',
                        }}
                    >
                        {tagData.employeeName + ' - ' + tagData.employeeCode}
                    </GarmentLink>
                ) : (
                    <div
                        style={{
                            display: 'block',
                            textAlign: 'center',
                            color: 'blue',
                            fontSize: '1rem',
                        }}
                    >
                        {tagData.employeeName + ' - ' + tagData.employeeCode}
                    </div>
                );
        }

        title = (
            <>
                <IntlMessages id={'shared.currentlyAttachedTo'} />
                <IntlMessages id={'shared.' + tagData.table} />:
            </>
        );
    } else if (tagData && tagData.moved) {
        title = (
            <>
                <IntlMessages id={'shared.currentlyAttachedTo'} />
                <IntlMessages id={'shared.anotherFactory'} />
            </>
        );
    } else if (tagData && tagData.removed) {
        title = (
            <>
                <IntlMessages id={'shared.notAttached'} />
            </>
        );
    }

    return (
        <div>
            {isLoadingData ? (
                <Spinner />
            ) : (
                <div>
                    <div className='mb-2 h4'>
                        {isTagFound && (
                            <>
                                <IntlMessages
                                    id={'mainInformation.employees.card'}
                                />{' '}
                                {isTagFound && <strong>{tagId}</strong>} {title}
                            </>
                        )}
                    </div>
                    {body}
                </div>
            )}
        </div>
    );
};

export default TagDetails;
