import { cardSignOut } from 'actions/Auth';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useIsNetworkOnline } from '../../hooks/useIsNetworkOnline';
import isSignOut from '../../util/IsSignOut';

// Used to get values when scanning a tag (whether a card or a batch)
const Scanner = (props) => {
    const [currentId, setCurrentId] = useState('');
    const [inputValue, setInputValue] = useState('');
    const isOnline = useIsNetworkOnline();

    const currentIdRef = useRef(currentId);
    const inputRef = useRef();

    useEffect(() => { 
        if(props.isAssigningCardsToBatches) {
            (!props.isAssigningCardsLoading && isOnline) && inputRef.current.focus();
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isOnline, props.isAssigningCardsLoading]);

    useEffect(() => {
        document.addEventListener('keydown', logKey);
        return () => document.removeEventListener('keydown', logKey);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (inputValue && inputValue.length === 10)
            checkScanTypeHandler(inputValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    // Scanning checks for 10 consective digits followed by an Enter
    // Must reset currentId after each scan to allow for further scans
    // Scanning is controlled by 'scan' props to allow the scanning functionality to be stopped when needed
    const logKey = (event) => {
        if (props.scan) {
            if (event.code.startsWith('Digit')) {
                updateCurrentId(
                    `${currentIdRef.current}${event.code.substring(5, 6)}`
                );
            } else if (event.code === 'Enter') {
                if (currentIdRef.current.length === 10)
                    checkScanTypeHandler(currentIdRef.current);
                else updateCurrentId('');
            }
        }
    };

    // Checks if scanned id is that of the user's card to sign out
    // Otherwise calls handler of parent component if found and reset currentId
    const checkScanTypeHandler = (id) => {
        updateCurrentId('');
        setInputValue('');
        if (isSignOut(id)) props.cardSignOut();
        else props.handleScan && props.handleScan(id);
    };

    const updateCurrentId = (neValue) => {
        setCurrentId(neValue);
        currentIdRef.current = neValue;
    };
    const inputChangeHandler = (e) => {
        const value = e.target.value;
        if (value.match(/^[0-9]*$/) && value.length < 11) {
            setInputValue(value);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                    value={inputValue}
                    ref={inputRef}
                    onChange={inputChangeHandler}
                    inputMode='numeric'
                    autoFocus
                    style={{ textAlign: 'center', width: 100 }}
                    disabled={props.isAssigningCardsToBatches && (props.isAssigningCardsLoading || !isOnline)}
                />
            </div>
            {props.children}
        </div>
    );
};

export default connect(null, { cardSignOut })(Scanner);
