import io from 'socket.io-client';
import { GET_SOCKET_URL } from '../../devtools/companyUrlSwitcher/utils';

const baseURI = GET_SOCKET_URL();

const userId =
    localStorage.getItem('user_id') &&
    JSON.parse(localStorage.getItem('user_id'))._id;

export const socket = io(baseURI, {
    query: {
        ...(userId && { userId }),
    },
});
