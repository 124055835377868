const SET_BATCH_MERGE_RULE = 'SET_BATCH_MERGE_RULE';
const SET_MERGING_TYPE = 'SET_MERGING_TYPE';

export const mergingRuleFields = [
    'cuttingOrderNumber',
    'color',
    'size',
    'batchesCount',
];

const MERGING_RULES_KEY = 'mergingRules';

const getSavedRules = () => {
    const savedRules = JSON.parse(
        localStorage.getItem(MERGING_RULES_KEY) || '{}'
    );

    return savedRules.cuttingOrderNumber !== undefined
        ? savedRules
        : {
              cuttingOrderNumber: false,
              color: false,
              size: false,
              batchesCount: false,
              typical: 1,
              maximum: 1,
              splitRemaining: false,
          };
};

const initialState = {
    mergingType: '',
    rules: getSavedRules(),
};

/**
 * @param taget {object}
 * @returns {{type: string, payload: object}}
 */
export const setMergeBatchRule = (target) => ({
    type: SET_BATCH_MERGE_RULE,
    payload: target,
});

/**
 * @param {'all' | 'selected'} type
 * @returns {{type: string}}
 */
export const setMergingType = (type) => ({
    type: SET_MERGING_TYPE,
    payload: type,
});

export const BatchesMergingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BATCH_MERGE_RULE: {
            const target = action.payload;

            const rules = {
                ...state.rules,
                [target.name]:
                    target.type === 'checkbox'
                        ? target.checked
                        : parseInt(target.value) || 1,
            };

            localStorage.setItem(MERGING_RULES_KEY, JSON.stringify(rules));
            return { ...state, rules };
        }
        case SET_MERGING_TYPE:
            return { ...state, mergingType: action.payload };
        default:
            return state;
    }
};
