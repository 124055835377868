import { FETCH_METABASE_DATA } from 'constants/ActionTypes';

const initialState = { data: [] };

// actions
export const setMetabaseData = (payload) => ({
    type: FETCH_METABASE_DATA,
    payload,
});

// reducer
export const routesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_METABASE_DATA: {
            localStorage.setItem('metabase', JSON.stringify({ ...state, data: action.payload }));
            return { ...state, data: action.payload };
        }
        default:
            return state;
    }
};
