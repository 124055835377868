import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import { GET_BASE_URL, GET_COMPANY } from './utils';
import { isTokenValid } from '../../shared/util/tokenValidation';
import { useSettings } from '../../shared/hooks/useSettings';
import { SwitcherModal } from './SwitcherModal';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const CompanyUrlSwitcher = ({ hideDevTool }) => {
    const mediaPrint = useMediaQuery('print');

    const { isDirectionRTL: rtl } = useSettings();
    const [showModal, setShowModal] = useState(false);
    const initialUrl = useMemo(GET_BASE_URL, []);
    const initialCompany = useMemo(GET_COMPANY, []);

    const onClose = (url, company) => {
        if (company !== initialCompany) {
            const keys = Object.keys(localStorage);
            keys.forEach((k) => {
                if (k.includes('Scope')) localStorage.removeItem(k);
            });

            isTokenValid(
                {
                    data: { force_logout: true, updateUserData: true },
                },
                true
            );
        } else if (url !== initialUrl) {
            window.location.reload(true);
        }

        setShowModal(false);
    };

    return (
        !hideDevTool && (
            <div style={{ display: mediaPrint ? 'none' : 'auto' }}>
                {!showModal && (
                    <div
                        dir={rtl ? 'rtl' : 'ltr'}
                        className={styles.container}
                        onClick={() => setShowModal(true)}
                    >
                        <FontAwesomeIcon
                            className={`${styles.icon} d-print-none`}
                            icon={faTools}
                        />
                    </div>
                )}

                {showModal && <SwitcherModal onClose={onClose} />}
            </div>
        )
    );
};
