import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import AgGrid from '../../../../shared/components/AgGridV2/AgGrid';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';
import styles from './OfflineInspectionsContent.module.scss';
import { apiRequest } from '../../../../shared/api/apiRequest';
import showNotificationMessage from '../../../../shared/util/NotificationMessage';
import { OfflineInlineInspectionsAPI } from './OfflineInlineInspectionsAPI';
import { useAuthuser } from '../../../../shared/hooks/useAuthuser';
import { useLocale } from '../../../../shared/hooks/useLocale';
import {
    inlineInspectionsSelector,
    inlineInspectionsTimestampSelector,
    clearInspections,
    setInlineInspectionsTimestamp,
} from '../../../../../../reducers/InlineInspections';
import { useDispatch, useSelector } from 'react-redux';

export const OfflineInspectionsContent = () => {
    useLocale();
    const [isLoading, setIsLoading] = useState(false);
    const inspections = useSelector(inlineInspectionsSelector);
    const timestamp = useSelector(inlineInspectionsTimestampSelector);

    const { company, factory } = useAuthuser();
    const dispatch = useDispatch();

    const columnDefs = [
        {
            headerTextId: 'shared.gridHeader.date',
            field: 'originTimestamp',
            valueFormatter: ({ value }) =>
                value ? moment(value).format('DD/MM/YYYY hh:mm a') : '',
            filterValueGetter: ({ data }) =>
                data.originTimestamp
                    ? moment(data.originTimestamp).format('DD/MM/YYYY hh:mm a')
                    : '',
        },
        {
            headerTextId: 'shared.gridHeader.inspectorName',
            field: 'inspectorName',
        },
        {
            headerTextId: 'shared.gridHeader.machineNumber',
            field: 'machineNumber',
        },
        {
            headerTextId: 'shared.gridHeader.worker',
            field: 'employeeName',
        },
    ];

    const recordInspections = () => {
        setIsLoading(true);

        dispatch(setInlineInspectionsTimestamp(moment()));

        const formattedInspections = [...inspections];
        formattedInspections.forEach((inspection) => {
            delete inspection.inspectorName;
            delete inspection.machineNumber;
            delete inspection.employeeName;
        });
        apiRequest(
            OfflineInlineInspectionsAPI.postReport,
            company,
            factory,
            formattedInspections
        )
            .then(() => {
                dispatch(clearInspections());
                showNotificationMessage(
                    'success',
                    <IntlMessages id='shared.success.add' />
                );
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(clearInspections());
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading && (
                <div className={styles.loadingContainer}>
                    <CachedIcon fontSize='large' />
                    <IntlMessages id='shared.isBeingUpdated' />
                </div>
            )}
            <div className='p-3'>
                <Button
                    variant='contained'
                    color='primary'
                    className='mr-2'
                    disabled={!inspections || inspections.length === 0}
                    onClick={recordInspections}
                >
                    <IntlMessages id='shared.recordInspections' />
                </Button>
                {timestamp && (
                    <>
                        <IntlMessages id='shared.lastTrial' />
                        <span className='ml-2'>
                            {moment(timestamp).format('YYYY-MM-DD h:mm a')}
                        </span>
                    </>
                )}
                <div className='mt-3'>
                    {inspections && inspections.length ? (
                        <AgGrid
                            columnDefs={columnDefs}
                            rowData={inspections}
                            gridHeight={400}
                        />
                    ) : (
                        <IntlMessages id='shared.noOfflineInspections' />
                    )}
                </div>
            </div>
        </>
    );
};
