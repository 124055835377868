import getTranslation from './Language';

export const formulateFactoryData = (data) => {
    const allSections = [];
    const tagsWithSections = new Map();
    const children = data.data.productionLinesAndSections
        .map((line) => {
            const sections = line.sections.map((section) => {
                allSections.push(section.id || section._id);
                if (section?.tags.length) {
                    section.tags.forEach((tag) => {
                        const currentTagSections = tagsWithSections.get(tag);
                        if (currentTagSections) {
                            tagsWithSections.set(tag, [
                                ...currentTagSections,
                                section.id || section._id,
                            ]);
                        } else {
                            tagsWithSections.set(tag, [
                                section.id || section._id,
                            ]);
                        }
                    });
                }

                return {
                    key: section.id || section._id,
                    title: section.name,
                    value: section.id || section._id,
                };
            });

            const uniqueSections = [];
            const seenKeys = new Set();

            sections.forEach((section) => {
                if (!seenKeys.has(section.key)) {
                    uniqueSections.push(section);
                    seenKeys.add(section.key);
                }
            });

            return {
                key: line.id || line._id,
                title: line.name,
                value: line.id || line._id,
                children: uniqueSections,
            };
        })
        .filter((line) => line.children.length > 0);

    const factoryTitle = data.factoryScope
        ? getTranslation('shared.theFactory')
        : getTranslation('shared.allLinesAndSections');

    return {
        factory: {
            title: factoryTitle,
            key: data.factoryScope ? 'factory' : 'allLinesAndSections',
            isFactoryScope: data.factoryScope || false,
            children,
        },
        allSections,
        tagsWithSections: Array.from(tagsWithSections, ([key, value]) => ({
            [key]: value,
        })),
    };
};
