import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import IntlMessages from '../../../../../util/IntlMessages';

export const Spinner = ({
    show = true,
    className = '',
    style = {},
    showMessage = false,
    message = 'shared.loadingDelayedReports',
}) =>
    show && (
        <div className={`loader-view mt-5 ${className}`} style={style}>
            <CircularProgress />
            {showMessage && (
                <div className={'mt-3'}>
                    <IntlMessages id={message} />
                </div>
            )}
        </div>
    );
