import showNotificationMessage from 'app/modules/shared/util/NotificationMessage';
import React from 'react';
import IntlMessages from 'util/IntlMessages';

export const errorHandlerNewPattern = (error) => {
    const ignoredErrorCodes = ['US05'];
    const errorMessage = error.message.includes('Network Error') ? (
        <IntlMessages id='shared.error.default' />
    ) : (
        error.message
    );
    if (!error.response) return showNotificationMessage('error', errorMessage);

    try {
        if (
            error.response &&
            error.response.data &&
            error.response.data.errors
        ) {
            error.response.data.errors.forEach((error) => {
                if (error.code) {
                    if (ignoredErrorCodes.includes(error.code)) return;
                    
                    return showNotificationMessage(
                        'error',
                        <IntlMessages
                            id={`shared.error.${error.code}`}
                            //defaultMessage=' '
                        />
                    );
                }

                showNotificationMessage('error', error.message);
            });
        } else {
            return showNotificationMessage('error', errorMessage);
        }
    } catch (error) {
        showNotificationMessage('error', errorMessage);
    }
};

export const errorHandlerOldPattern = (error) => {
    if (!error.response) return showNotificationMessage('error', error.message);

    if (error.response && error.response.data && error.response.data.error) {
        error.response.data.error.forEach((error) => {
            if (error.code)
                return showNotificationMessage(
                    'error',
                    <IntlMessages
                        id={`shared.error.${error.code}`}
                        //defaultMessage=' '
                    />
                );

            showNotificationMessage('error', error.msg);
        });
    } else {
        return showNotificationMessage('error', error.message);
    }
};
