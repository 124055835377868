import axiosInstance from '../../shared/api/axiosInstance';

const SectionTagsAPI = {
    getAll: (company, factory) =>
        axiosInstance.get(`${company}/${factory}/sectionTags`),

    add: (company, factory, tagData) =>
        axiosInstance.post(`${company}/${factory}/sectionTags`, tagData),

    edit: (company, factory, tagId, tagData) =>
        axiosInstance.put(`${company}/${factory}/sectionTags/${tagId}`, tagData),

    delete: (company, factory, tagId) =>
        axiosInstance.delete(`${company}/${factory}/sectionTags/${tagId}`),
};

export default SectionTagsAPI;
