import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers/index';
import rootSaga from '../sagas/index';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;
export default function configureStore(initialState) {
  store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (import.meta.hot) {
    // Enable Vite hot module replacement for reducers
    import.meta.hot.accept('../reducers/index', async () => {
      const nextRootReducer = (await import('../reducers/index')).default;
      store.replaceReducer(nextRootReducer(history));
    });
  }

  return store;
}

export { history, store };
