import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import notificationsSagas from './Notifications';
import cuttingOrderSagas from './CuttingOrder';
import translationsSagas from './Translations';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        notificationsSagas(),
        cuttingOrderSagas(),
        translationsSagas(),
    ]);
}
