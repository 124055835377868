export const StickerStyle = `
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&family=Roboto:wght@400;700&display=swap');

:root {
    --page-width: 5cm;
    --page-height: 8cm;
    --orientation: landscape;
    --margin: 0;
}

@page {
    margin: 0;
}

html, body {
    margin: 0;
}

.rtl {
    direction: rtl;
}

.sticker {
    border: 1px black dashed;
    float: right;
    padding: 0 0.05cm 0 0.05cm;
    width: var(--page-width) !important;
    height: var(--page-height) !important;
}

.separateLabels {
    margin: var(--margin) auto;
    page-break-after: always;
    float: none;
    padding: 0;
    line-height: 1.5;
}

.singleBatch {
    width: 100%;
    border-collapse: collapse;
    color: #000;
    table-layout: fixed;
}

.singleBatch tbody tr {
    vertical-align: top;
    font-size: 0.38cm !important;
    border-bottom: 1px black dotted;
}

.singleBatch tbody tr td {
    padding: 0.08cm 0.2cm 0.08cm 0.2cm;
}

.singleBatch tbody tr.small-font {
    font-size: 0.3cm !important;
}
.singleBatch tbody tr.medium-font {
    font-size: 0.35cm !important;
}

.singleBatch tbody tr td:nth-child(1) {
    width: 1.7cm;
}

.singleBatch tbody tr td:nth-child(2) {
    font-weight: bold;
    word-wrap: break-word;
}

.wrap {
    word-wrap: break-word;
}

.style_name {
    font-weight: bold;
    padding-top: 0;
    direction: ltr;
    text-align: left;
    word-break: break-all;
}

.cell {
    display: flex;
    justify-content: space-between;
}

.icon {
    width: 0.8cm;
    padding-bottom: 8px;
}

.spacing {
    page-break-after: always;
}

.noBorderBottom {
    border-bottom: 0 !important;
}

.noPaddingLeft {
    padding-left: 0 !important;
}

.noPaddingRight {
    padding-right: 0 !important;
}

.alignLeft {
    text-align: left;
}

.alignRight {
    text-align: right;
}

.justifyContent {
    display: flex;
    justify-content: space-between;
}

.marginLeft {
    margin-left: 20px;
}

.marginRight {
    margin-right: 20px;
}

.noMargin {
    margin: 0;
}

.highlight {
    background: #99f3fd;
}

.arabicFont {
    font-family: 'Cairo', sans-serif !important;
}

.englishFont {
    font-family: 'Roboto', sans-serif !important;
}

@font-face {
    font-family: 'Cairo';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cairo';
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-weight: bold;
    font-style: normal;
}
.d-flex {
   display: flex !important;
}
.w-50 {
   width: 50% !important;
}
.font-weight-bold {
   font-weight: bold !important;
}
.pb-0 {
   padding-bottom: 0!important;
}
.pt-0 {
   padding-top: 0!important;
}
 `;
