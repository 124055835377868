import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { getNotifications } from 'actions/Notifications';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import styles from '../../../../styles/theme.js';
import NotificationItem from '../AppNotification/NotificationItem';
import CardHeader from '../CardHeader/CardHeader';

class AppNotificationModal extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.location.hash !== this.props.location.hash) {
            if (!this.props.location.hash) {
                this.onClose();
            }
        }
    }

    onClose = () => {
        this.props.onClose();
    };

    render = () => {
        const {
            notifications,
            isNotificationModalOpen,
            onDelete,
            numberOfNotifications,
            slice,
            getNotifications,
        } = this.props;

        return (
            <Dialog
                fullScreen
                open={isNotificationModalOpen}
                onClose={this.onClose}
            >
                <AppBar
                    className={`${styles.bgColorPrimary} position-relative `}
                >
                    <Toolbar>
                        <CardHeader
                            styleName='w-100 align-items-center text-white'
                            noOfNotifications={numberOfNotifications}
                            heading={
                                <IntlMessages id='appNotification.title' />
                            }
                            onDelete={onDelete}
                            showCloseIcon
                            showDeleteIcon
                            onClose={this.onClose}
                        />
                    </Toolbar>
                </AppBar>
                <DialogContent className='p-0'>
                    {notifications.length > 0 ? (
                        <List>
                            {notifications.map((notification, index) => (
                                <React.Fragment key={index}>
                                    <NotificationItem
                                        swipe
                                        onClickNotification={
                                            this.props.clickNotification
                                        }
                                        notification={notification}
                                    />
                                    {index !== notifications.length - 1 && (
                                        <Divider />
                                    )}
                                </React.Fragment>
                            ))}
                            {notifications.length !== numberOfNotifications && (
                                <div className='d-flex justify-content-center align-items-center mt-2'>
                                    <Button
                                        onClick={() => getNotifications(slice)}
                                    >
                                        <IntlMessages id='notifications.loadMore' />
                                    </Button>
                                </div>
                            )}
                        </List>
                    ) : (
                        <div className='d-flex align-items-center h-100 justify-content-center'>
                            <IntlMessages id='appNotification.message' />
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        );
    };
}
const mapStateToProps = ({ auth: { authUser }, notifications: { slice } }) => ({
    authUser,
    slice,
});

export default connect(mapStateToProps, { getNotifications })(
    AppNotificationModal,
);
