import {
    CHANGE_DIRECTION,
    CHANGE_NAVIGATION_STYLE,
    DRAWER_TYPE,
    FIXED_DRAWER,
    HORIZONTAL_MENU_POSITION,
    INSIDE_THE_HEADER,
    SWITCH_LANGUAGE,
    TOGGLE_COLLAPSED_NAV,
    OPEN_CHAT_COMPONENT,
    VERTICAL_NAVIGATION,
    WINDOW_WIDTH,
} from 'constants/ActionTypes';
import {
    companyIdleTime,
    factoryIdleTimeEnabled,
} from '../app/modules/shared/util/idleTimeEnabled';
import {
    companyBatchGrouping,
    factoryBatchGroupingEnabled,
} from '../app/modules/shared/util/batchGroupingEnabled';
import {
    companyAQL,
    factoryAQLEnabled,
} from '../app/modules/shared/util/AQLEnabled';
import {
    companyCutting,
    factoryCuttingEnabled,
} from '../app/modules/shared/util/cuttingEnabled';
import { factoryAutomateBeatsEnabled } from 'app/modules/shared/util/factoryAutomateBeatEnabled';
import { factoryTimezone } from 'app/modules/shared/util/factoryTimezone';

const rltLocale = ['ar'];
let userLocale = {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'Arabic',
    icon: 'sa',
};
let isDirectionRTL = 'true';
let lang = localStorage.getItem('lang');
if (lang) {
    userLocale = JSON.parse(lang);
    if (userLocale.locale !== 'ar') {
        isDirectionRTL = false;
    }
}

const initialSettings = {
    navCollapsed: false,
    chatOpenned: false,
    drawerType: FIXED_DRAWER,
    width: window.innerWidth,
    isDirectionRTL,
    navigationStyle: VERTICAL_NAVIGATION,
    horizontalNavPosition: INSIDE_THE_HEADER,
    locale: userLocale,
    companyIdleTime: companyIdleTime(),
    factoryIdleTime: factoryIdleTimeEnabled(),
    companyBatchGrouping: companyBatchGrouping(),
    factoryBatchGrouping: factoryBatchGroupingEnabled(),
    companyAQL: companyAQL(),
    factoryAQL: factoryAQLEnabled(),
    companyCutting: companyCutting(),
    factoryCutting: factoryCuttingEnabled(),
    factoryAutomateBeats: factoryAutomateBeatsEnabled(),
    factoryTimezone: factoryTimezone(),
};

export const currentLocale = (state) => state.settings.locale;

const SET_COMPANY_IDLE_TIME = 'SET_COMPANY_IDLE_TIME';
const SET_FACTORY_IDLE_TIME = 'SET_FACTORY_IDLE_TIME';

const SET_COMPANY_BATCH_GROUPING = 'SET_COMPANY_BATCH_GROUPING';
const SET_FACTORY_BATCH_GROUPING = 'SET_FACTORY_BATCH_GROUPING';

const SET_COMPANY_AQL = 'SET_COMPANY_AQL';
const SET_FACTORY_AQL = 'SET_FACTORY_AQL';

const SET_COMPANY_CUTTING = 'SET_COMPANY_CUTTING';
const SET_FACTORY_CUTTING = 'SET_FACTORY_CUTTING';
const SET_FACTORY_AUTOMATE_BEATS = 'SET_FACTORY_AUTOMATE_BEATS';
const SET_FACTORY_TIME_ZONE = 'SET_FACTORY_TIME_ZONE';

export const setCompanyIdleTime = (payload) => ({
    type: SET_COMPANY_IDLE_TIME,
    payload,
});

export const setFactoryIdleTime = (payload) => ({
    type: SET_FACTORY_IDLE_TIME,
    payload,
});

export const setCompanyBatchGrouping = (payload) => ({
    type: SET_COMPANY_BATCH_GROUPING,
    payload,
});

export const setFactoryBatchGrouping = (payload) => ({
    type: SET_FACTORY_BATCH_GROUPING,
    payload,
});

export const setCompanyAQL = (payload) => ({
    type: SET_COMPANY_AQL,
    payload,
});

export const setFactoryAQL = (payload) => ({
    type: SET_FACTORY_AQL,
    payload,
});

export const setCompanyCutting = (payload) => ({
    type: SET_COMPANY_CUTTING,
    payload,
});

export const setFactoryCutting = (payload) => ({
    type: SET_FACTORY_CUTTING,
    payload,
});

export const setFactoryAutomateBeats = (payload) => ({
    type: SET_FACTORY_AUTOMATE_BEATS,
    payload,
});

export const setFactoryTimezone = (payload) => ({
    type: SET_FACTORY_TIME_ZONE,
    payload,
});

const settings = (state = initialSettings, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return {
                ...state,
                navCollapsed: false,
            };
        case DRAWER_TYPE:
            return {
                ...state,
                drawerType: action.drawerType,
            };
        case TOGGLE_COLLAPSED_NAV:
            return {
                ...state,
                navCollapsed: action.isNavCollapsed,
            };
        case OPEN_CHAT_COMPONENT:
            return {
                ...state,
                chatOpenned: action.isChatOpenned,
            };
        case WINDOW_WIDTH:
            return {
                ...state,
                width: action.width,
            };
        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
                isDirectionRTL: rltLocale.includes(action.payload.locale),
            };
        case CHANGE_DIRECTION:
            return {
                ...state,
                isDirectionRTL: !state.isDirectionRTL,
            };

        case CHANGE_NAVIGATION_STYLE:
            return {
                ...state,
                navigationStyle: action.payload,
            };

        case HORIZONTAL_MENU_POSITION:
            return {
                ...state,
                horizontalNavPosition: action.payload,
            };
        case SET_COMPANY_IDLE_TIME:
            return { ...state, companyIdleTime: action.payload };
        case SET_FACTORY_IDLE_TIME:
            return { ...state, factoryIdleTime: action.payload };
        case SET_COMPANY_BATCH_GROUPING:
            return { ...state, companyBatchGrouping: action.payload };
        case SET_FACTORY_BATCH_GROUPING:
            return { ...state, factoryBatchGrouping: action.payload };
        case SET_COMPANY_AQL:
            return { ...state, companyAQL: action.payload };
        case SET_FACTORY_AQL:
            return { ...state, factoryAQL: action.payload };
        case SET_COMPANY_CUTTING:
            return { ...state, companyCutting: action.payload };
        case SET_FACTORY_CUTTING:
            return { ...state, factoryCutting: action.payload };
        case SET_FACTORY_AUTOMATE_BEATS:
            return { ...state, factoryAutomateBeats: action.payload };
        case SET_FACTORY_TIME_ZONE:
            return { ...state, factoryTimezone: action.payload };
        default:
            return state;
    }
};

export default settings;
