export const OFFLINE_INLINE_INSPECTIONS = 'offlineInlineInspections';
export const OFFLINE_INLINE_INSPECTIONS_TIMESTAMP =
    'offlineInlineInspectionsTimestamp';

const ADD_INLINE_INSPECTION = 'ADD_INLINE_INSPECTION';
const CLEAR_INLINE_INSPECTIONS = 'CLEAR_INLINE_INSPECTIONS';
const SET_INLINE_INSPECTIONS_TIMESTAMP = 'SET_INLINE_INSPECTIONS_TIMESTAMP';

const getInspections = () => {
    const inspections = localStorage.getItem(OFFLINE_INLINE_INSPECTIONS);
    return inspections ? JSON.parse(inspections) : [];
};

const getTimestamp = () => {
    const timestamp = localStorage.getItem(
        OFFLINE_INLINE_INSPECTIONS_TIMESTAMP
    );
    return timestamp ? JSON.parse(timestamp) : '';
};

const initialState = {
    inspections: getInspections(),
    timestamp: getTimestamp(),
};

export const inlineInspectionsSelector = (state) =>
    state.inlineInspections.inspections;
export const inlineInspectionsTimestampSelector = (state) =>
    state.inlineInspections.timestamp;

export const addInlineInspection = (inspection) => ({
    type: ADD_INLINE_INSPECTION,
    payload: inspection,
});

export const clearInspections = () => ({
    type: CLEAR_INLINE_INSPECTIONS,
});

export const setInlineInspectionsTimestamp = (timestamp) => ({
    type: SET_INLINE_INSPECTIONS_TIMESTAMP,
    payload: timestamp,
});

export const InlineInspectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_INLINE_INSPECTION: {
            const newInspections = state.inspections.length
                ? [...state.inspections, action.payload]
                : [action.payload];
            localStorage.setItem(
                OFFLINE_INLINE_INSPECTIONS,
                JSON.stringify(newInspections)
            );
            return { ...state, inspections: newInspections };
        }
        case CLEAR_INLINE_INSPECTIONS: {
            localStorage.removeItem(OFFLINE_INLINE_INSPECTIONS);
            return { ...state, inspections: [] };
        }
        case SET_INLINE_INSPECTIONS_TIMESTAMP: {
            localStorage.setItem(
                OFFLINE_INLINE_INSPECTIONS_TIMESTAMP,
                JSON.stringify(action.payload)
            );
            return { ...state, timestamp: action.payload };
        }
        default:
            return state;
    }
};
