import { createSelector } from 'reselect';

const SET_STAGES = 'SET_STAGES';
const SET_STAGE_NAME = 'SET_STAGE_NAME';
const ADD_STAGE = 'ADD_STAGE';
const DELETE_STAGE = 'DELETE_STAGE';
const CHANGE_STAGES_ORDER = 'CHANGE_STAGES_ORDER';
const UPDATE_STAGE = 'UPDATE_STAGE';
const UPDATE_PROCESS = 'UPDATE_PROCESS';
const CHANGE_PROCESSES_ORDER = 'CHANGE_PROCESSES_ORDER';
const MOVE_PROCESSES = 'MOVE_PROCESSES';
const DELETE_PROCESS = 'DELETE_PROCESS';
const UPDATE_STANDARD_PROCESS = 'UPDATE_STANDARD_PROCESS';
const ADD_AUTOMATIC_DEPENDENCIES = 'ADD_AUTOMATIC_DEPENDENCIES';

const initialState = {
    stages: [],
};

export const stagesSelector = (state) => state.style.stages;

export const getProcessesMap = createSelector(stagesSelector, (stages) => {
    const processesMap = new Map();
    stages.forEach((stage) => {
        stage.processes.forEach((process) => {
            processesMap.set(
                process.id,
                `${process.name} ${process.suffix ? '- ' + process.suffix : ''}`
            );
        });
    });
    return processesMap;
});

export const setStages = (stages) => ({
    type: SET_STAGES,
    payload: stages,
});

export const setStageName = (id, name) => ({
    type: SET_STAGE_NAME,
    payload: { id, name },
});

export const addStage = (id, name) => ({
    type: ADD_STAGE,
    payload: { id, name },
});

export const deleteStage = (id) => ({
    type: DELETE_STAGE,
    payload: id,
});

export const changeStagesOrder = (tabA, tabB) => ({
    type: CHANGE_STAGES_ORDER,
    payload: { tabA, tabB },
});

export const updateStage = (id, processes, append = false) => ({
    type: UPDATE_STAGE,
    payload: { id, processes, append },
});

export const updateProcess = (stageId, processId, data) => ({
    type: UPDATE_PROCESS,
    payload: { stageId, processId, data },
});

export const changeProcessesOrder = (stageId, orderedProcesses) => ({
    type: CHANGE_PROCESSES_ORDER,
    payload: { stageId, orderedProcesses },
});

export const moveProcesses = (processes, oldStage, newStage) => ({
    type: MOVE_PROCESSES,
    payload: { processes, oldStage, newStage },
});

export const deleteProcess = (process) => ({
    type: DELETE_PROCESS,
    payload: process,
});

export const updateStandardProcess = (data) => ({
    type: UPDATE_STANDARD_PROCESS,
    payload: { data },
});

export const addAutomaticDependencies = (stageId, processes) => ({
    type: ADD_AUTOMATIC_DEPENDENCIES,
    payload: { stageId, processes },
});

export const StyleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STAGES: {
            return { ...state, stages: action.payload };
        }
        case SET_STAGE_NAME: {
            const { id, name } = action.payload;
            return {
                ...state,
                stages: state.stages.map((s) =>
                    s.id === id ? { ...s, name } : s
                ),
            };
        }
        case ADD_STAGE: {
            const { id, name } = action.payload;
            return {
                ...state,
                stages: [
                    ...state.stages,
                    {
                        name,
                        id,
                        processes: [],
                    },
                ],
            };
        }
        case DELETE_STAGE: {
            return {
                ...state,
                stages: state.stages.filter((s) => s.id !== action.payload),
            };
        }
        case CHANGE_STAGES_ORDER: {
            const { tabA, tabB } = action.payload;
            let temp = state.stages[tabA];

            return {
                ...state,
                stages: state.stages.map((stage, index) => {
                    if (index === tabA) return state.stages[tabB];
                    else if (index === tabB) return temp;
                    else return stage;
                }),
            };
        }
        case UPDATE_STAGE: {
            const { id, processes, append } = action.payload;
            return {
                ...state,
                stages: state.stages.map((s) =>
                    s.id === id
                        ? {
                              ...s,
                              processes: append
                                  ? [...s.processes, ...processes]
                                  : [...processes],
                          }
                        : s
                ),
            };
        }
        case UPDATE_PROCESS: {
            const { stageId, processId, data } = action.payload;
            return {
                ...state,
                stages: state.stages.map((s) =>
                    s.id === stageId
                        ? {
                              ...s,
                              processes: s.processes.map((p) =>
                                  p.id === processId ? { ...data } : p
                              ),
                          }
                        : s
                ),
            };
        }
        case CHANGE_PROCESSES_ORDER: {
            const { stageId, orderedProcesses } = action.payload;
            return {
                ...state,
                stages: state.stages.map((s) =>
                    s.id === stageId
                        ? {
                              ...s,
                              processes: orderedProcesses.map(
                                  (item, index) => ({
                                      ...item,
                                      order: index + 1,
                                  })
                              ),
                          }
                        : s
                ),
            };
        }
        case MOVE_PROCESSES: {
            const { processes, oldStage, newStage } = action.payload;
            const processIds = processes.map((p) => p.id);

            return {
                ...state,
                stages: state.stages.map((s) => {
                    if (s.id === oldStage) {
                        return {
                            ...s,
                            processes: s.processes.filter(
                                (p) => !processIds.includes(p.id)
                            ),
                        };
                    } else if (s.id === newStage) {
                        return {
                            ...s,
                            processes: [
                                ...s.processes,
                                ...processes.map((p) => {
                                    return {
                                        ...p,
                                        stage: newStage,
                                    };
                                }),
                            ],
                        };
                    } else return s;
                }),
            };
        }
        case DELETE_PROCESS: {
            return {
                ...state,
                stages: state.stages.map((s) => {
                    return {
                        ...s,
                        processes: s.processes
                            .filter((p) => p.id !== action.payload.id)
                            .map((p) => {
                                return {
                                    ...p,
                                    dependencies: p.dependencies.filter(
                                        (d) => d !== action.payload.id
                                    ),
                                };
                            }),
                    };
                }),
            };
        }
        case UPDATE_STANDARD_PROCESS: {
            const { data } = action.payload;
            return {
                ...state,
                stages: state.stages.map((s) => {
                    return {
                        ...s,
                        processes: s.processes.map((p) =>
                            p.standardProcessId === data._id
                                ? {
                                      ...p,
                                      name: data.name,
                                      previewName: data.previewName || '',
                                      directCost: data.directCost,
                                      expectedTime: data.expectedTime
                                          ? parseFloat(data.expectedTime)
                                          : 0,
                                      machineTypesNames:
                                          data.machineTypesDependencies.map(
                                              (m) => m.machineType.name
                                          ),
                                  }
                                : p
                        ),
                    };
                }),
            };
        }
        case ADD_AUTOMATIC_DEPENDENCIES: {
            const { stageId, processes } = action.payload;
            const processesMap = new Map();

            processes.forEach((p) => {
                processesMap.set(p._id, p.dependencies);
            });

            return {
                ...state,
                stages: state.stages.map((s) => {
                    if (s.id === stageId) {
                        return {
                            ...s,
                            processes: s.processes.map((p) => {
                                return {
                                    ...p,
                                    dependencies: processesMap.get(p.id),
                                };
                            }),
                        };
                    } else return s;
                }),
            };
        }
        default:
            return state;
    }
};
