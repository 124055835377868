const SET_BUNDLING_RULES = 'SET_BUNDLING_RULES';

const BUNDLING_RULES_KEY = 'bundlingRules';

export const bundlingRulesSelector = (state) => state.batchesBundling.rules;

const getSavedRules = () => {
    const savedRules = JSON.parse(
        localStorage.getItem(BUNDLING_RULES_KEY) || '{}'
    );

    return savedRules.roll !== undefined
        ? savedRules
        : {
              roll: false,
              colorShade: false,
              quantity: false,
              separateSizes: false,
              typical: 0,
              maximum: 0,
          };
};

const initialState = {
    rules: getSavedRules(),
    bundlingChanged: false,
};

export const setBundlingRules = (target) => ({
    type: SET_BUNDLING_RULES,
    payload: target,
});

export const BatchesBundlingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BUNDLING_RULES: {
            const target = action.payload;
            const rules = {
                ...state.rules,
                [target.name]: parseInt(target.value) || target.checked,
            };

            localStorage.setItem(BUNDLING_RULES_KEY, JSON.stringify(rules));
            return { ...state, rules };
        }
        default:
            return state;
    }
};
