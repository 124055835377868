import React from 'react';
import Sweetalert from 'react-bootstrap-sweetalert';

const SweetAlert = (props) => {
    const {
        onCancel,
        onConfirm,
        message,
        title,
        show,
        confirmBtnText,
        cancelBtnText,
        confirmBtnStyle,
    } = props;
    return (
        <Sweetalert
            show={show}
            showCancel
            cancelBtnText={cancelBtnText}
            confirmBtnText={confirmBtnText}
            confirmBtnBsStyle={confirmBtnStyle}
            cancelBtnBsStyle='default'
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            {message}
        </Sweetalert>
    );
};

export default SweetAlert;
