import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import IsAuthUserRoute from './Guards/IsAuthUserRoute';
import LayoutRoute from './Guards/LayoutRoute';
import NotAuthUserRoute from './Guards/NotAuthUserRoute';
import routes from './routes';
import {
    createMetabaseRoutes,
    getMetabaseDataFromLocalStoreage,
} from './utils/Routes';

const Router = () => {
    const { data } = useSelector((state) => state.routes);
    const metabaseParsedData = getMetabaseDataFromLocalStoreage();
    const metabaseRoutes = data.length
        ? createMetabaseRoutes(data)
        : createMetabaseRoutes(metabaseParsedData);
    const newRoutes = [...metabaseRoutes, ...routes];

    return (
        <Switch>
            {newRoutes.map((route, index) => {
                switch (route.auth) {
                    case 'admin':
                    case true:
                        return (
                            <IsAuthUserRoute
                                key={index}
                                compt={route.component}
                                exact={route.exact}
                                path={route.path}
                                layout={route.layout}
                                auth={route.auth}
                                permissions={route.permissions}
                                canActivate={route.canActivate}
                            />
                        );
                    case false:
                        return (
                            <NotAuthUserRoute
                                key={index}
                                compt={route.component}
                                exact={route.exact}
                                path={route.path}
                                layout={route.layout}
                            />
                        );
                    case null:
                    default:
                        return (
                            <LayoutRoute
                                key={index}
                                compt={route.component}
                                exact={route.exact}
                                path={route.path}
                                layout={route.layout}
                            />
                        );
                }
            })}
            <Route component={() => <Redirect to='/404' />} />
        </Switch>
    );
};

export default memo(Router);
