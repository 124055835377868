import axios from 'axios';
import { GET_BASE_URL } from '../../devtools/companyUrlSwitcher/utils';
import { onFulfilled, onRejected } from './axiosInstancesInterceptors';

const baseURL = GET_BASE_URL();

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
});

axiosInstance.interceptors.response.use(onFulfilled, onRejected);

export default axiosInstance;
