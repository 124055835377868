import Button from '@material-ui/core/Button';
import React from 'react';

const AgGridButtons = ({ buttons, gridApi }) => {
    return buttons.map((button, index) => (
        <Button
            ref={button.ref}
            className={`mb-1 mx-1 ${button.className || ''}`}
            size='small'
            key={index}
            style={button.style || {}}
            variant={button.variant}
            color={button.color}
            disabled={button.disabled}
            onClick={() => {
                button.action(gridApi);
            }}
        >
            {button.label}
        </Button>
    ));
};

export default AgGridButtons;
