import { GET_TRANSLATIONS_SUCCESS } from 'constants/ActionTypes';

const initialState = {
    translations: {},
};

export const TranslationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                translations: action.translations,
            };
        default:
            return state;
    }
};
