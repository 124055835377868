import React, { useEffect, useState } from 'react';
import {
    DEV_TOOLS_SET_COMPANY,
    DEV_TOOLS_SET_URL,
    GET_BASE_URL,
    GET_DISPLAYS_URL,
    GET_LARAVEL_URL,
    GET_SOCKET_URL,
    GET_COMPANY,
    GET_DEVICES_BASE_URL,
} from './utils';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const DEV_BACKEND_ENVIRONMENTS = ['qa', 'staging', 'sandbox'];

const DEV_BACKENDS = DEV_BACKEND_ENVIRONMENTS.map((env) => ({
    name: env,
    url: `https://web-api.${env}.garment.link/api`,
    socketUrl: `https://web-api.${env}.garment.link/`,
    laravelUrl: `${env}.garment.link`,
    displaysUrl: `display.${env}.garment.link`,
}));

const BACKENDS = [
    ...DEV_BACKENDS,
    {
        disabled: true, // replace this with true to add production to your env
        name: 'production',
        url: 'https://web-api.garment.io/api/',
        socketUrl: 'https://web-api.garment.io/',
        laravelUrl: 'garment.io',
        displaysUrl: 'garmentio.com/',
    },
];

const filteredBackends = BACKENDS.filter((b) => !b.disabled);
const backendMap = Object.fromEntries(filteredBackends.map((b) => [b.url, b]));
const backendChecker = (link) => {
    const linksIndex = filteredBackends.findIndex((item) => item.url === link);

    if (linksIndex === -1) return 'custom';

    const index = filteredBackends.findIndex((backend) => {
        return (
            backend.url === filteredBackends[linksIndex].url &&
            backend.devicesBaseURL ===
                filteredBackends[linksIndex].devicesBaseURL &&
            backend.socketUrl === filteredBackends[linksIndex].socketUrl &&
            backend.laravelUrl === filteredBackends[linksIndex].laravelUrl &&
            backend.displaysUrl === filteredBackends[linksIndex].displaysUrl
        );
    });

    return index >= 0 ? filteredBackends[index].url : 'custom';
};

export const SwitcherModal = ({ onClose }) => {
    const [url, setUrl] = useState(backendChecker(GET_BASE_URL()));
    const [company, setCompany] = useState(GET_COMPANY);
    const [showCustomInputs, setShowCustomInputs] = useState(
        !Object.keys(backendMap).includes(url)
    );
    const [customData, setCustomData] = useState({
        url: GET_BASE_URL(),
        devicesBaseURL: GET_DEVICES_BASE_URL(),
        socketUrl: GET_SOCKET_URL(),
        laravelUrl: GET_LARAVEL_URL(),
        displaysUrl: GET_DISPLAYS_URL(),
    });

    useEffect(() => {
        if (Object.keys(customData).length > 0) {
            DEV_TOOLS_SET_URL(
                customData.url,
                customData.devicesBaseURL,
                customData.socketUrl,
                customData.laravelUrl,
                customData.displaysUrl
            );
        }
    }, [customData]);

    const currentUrl = url === 'custom' ? customData.url : url;
    const close = () => onClose(currentUrl, company);

    return (
        <Dialog open={true} onClose={close} maxWidth='md'>
            <DialogTitle id='form-dialog-title'>
                Switch Company, and Backend
            </DialogTitle>
            <DialogContent className='m-2'>
                <FormControl className='w-100'>
                    <InputLabel id='backend-selector'>Backend Url</InputLabel>
                    <Select
                        labelId='backend-selector'
                        value={url}
                        onChange={(e) => {
                            /** @type {string} */
                            const newUrl = e.target.value;

                            if (Object.keys(backendMap).includes(newUrl)) {
                                setUrl(newUrl);
                                setShowCustomInputs(false);
                                DEV_TOOLS_SET_URL(
                                    newUrl,
                                    backendMap[newUrl].devicesBaseURL,
                                    backendMap[newUrl].socketUrl,
                                    backendMap[newUrl].laravelUrl,
                                    backendMap[newUrl].displaysUrl
                                );
                            } else {
                                setUrl(newUrl);
                                setShowCustomInputs(true);
                            }
                        }}
                    >
                        {filteredBackends.map(({ name, url }) => (
                            <MenuItem key={name} value={url}>
                                {name}
                            </MenuItem>
                        ))}
                        <MenuItem value={'custom'}>Custom</MenuItem>
                    </Select>
                </FormControl>
                {showCustomInputs && (
                    <>
                        <FormControl className='w-100 mb-3 mt-2'>
                            <TextField
                                label='Backend Url'
                                type='text'
                                value={customData.url}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCustomData((oldState) => ({
                                        ...oldState,
                                        url: value,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3 mt-2'>
                            <TextField
                                label='socket Url'
                                type='text'
                                value={customData.socketUrl}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCustomData((oldState) => ({
                                        ...oldState,
                                        socketUrl: value,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3 mt-2'>
                            <TextField
                                label='Devices backend Url'
                                type='text'
                                value={customData.devicesBaseURL}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCustomData((oldState) => ({
                                        ...oldState,
                                        devicesBaseURL: value,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3 mt-2'>
                            <TextField
                                label='Laravel Url'
                                type='text'
                                value={customData.laravelUrl}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCustomData((oldState) => ({
                                        ...oldState,
                                        laravelUrl: value,
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl className='w-100 mb-3 mt-2'>
                            <TextField
                                label='Displays Url'
                                type='text'
                                value={customData.displaysUrl}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setCustomData((oldState) => ({
                                        ...oldState,
                                        displaysUrl: value,
                                    }));
                                }}
                            />
                        </FormControl>
                    </>
                )}
                <FormControl className='w-100 mb-3 mt-2'>
                    <TextField
                        label='Company Selector'
                        type='text'
                        value={company}
                        onChange={(e) => {
                            setCompany(e.target.value);
                            DEV_TOOLS_SET_COMPANY(e.target.value);
                        }}
                    />
                    <FormHelperText>
                        <span className='text-danger'>
                            Note: Page will refresh if the url changes and it
                            will updateUserData if the company changes
                        </span>
                    </FormHelperText>
                </FormControl>
            </DialogContent>
        </Dialog>
    );
};
