import arLocalTranslations from '../../../../lngProvider/defaultApp-ar.json';
import enLocalTranslations from '../../../../lngProvider/defaultApp-en.json';
import frLocalTranslations from '../../../../lngProvider/defaultApp-fr.json';

const getNotAuthTranslation = (locale, key) => {
    const localTranslations =
            locale === 'en'
                ? enLocalTranslations
                : locale === 'fr'
                ? frLocalTranslations
                : arLocalTranslations;
    
    return localTranslations[key] || [key];
};

export default getNotAuthTranslation;
