import { store } from 'store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {string}
 */
 export const factoryTimezone = () => {
    try {
        return JSON.parse(localStorage.user_id)?.factoryData?.timeZone || 'Africa/Cairo';
    } catch (e) {
        return e.message;
    }
};

// Use this method with any utility function, which is not a react component
export const getFactoryTimezone = () => {
    const state = store.getState();
    return state.settings.factoryTimezone;
};

// Use this hook with regular react components
export const useFactoryTimezone = () => 
    useSelector(({ settings }) => {
        return settings.factoryTimezone && settings.factoryTimezone;
});