export const getPrintStyle = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orientation = urlParams.get('orientation');
    const width = urlParams.get('width');
    const height = urlParams.get('height');
    const margin = urlParams.get('margin');

    document.documentElement.style.setProperty('--page-width', `${width}cm`);
    document.documentElement.style.setProperty('--page-height', `${height}cm`);
    document.documentElement.style.setProperty('--orientation', orientation);
    document.documentElement.style.setProperty(
        '--margin',
        `${margin / 2}cm auto`
    );
};
