const SET_SELECTED_BATCHES = 'SET_SELECTED_BATCHES';
const SET_ALL_BATCHES = 'SET_ALL_BATCHES';

const initialState = {
    allBatches: [],
    selectedBatches: [],
};

export const setSelectedBatches = (selectedBatches) => ({
    type: SET_SELECTED_BATCHES,
    payload: selectedBatches,
});

export const setAllBatches = (batches) => ({
    type: SET_ALL_BATCHES,
    payload: batches,
});

export const BatchesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_BATCHES:
            return { ...state, selectedBatches: action.payload };
        case SET_ALL_BATCHES:
            return { ...state, allBatches: action.payload };
        default:
            return state;
    }
};

export const allBatchesSelector = (state) => state.batches.allBatches;
