const SET_COLORS = 'SET_COLORS';

const initialState = {
    colors: null,
};

export const colorsSelector = (state) => state.colors;

export const setColors = (colors) => ({
    type: SET_COLORS,
    payload: colors,
});

export const ColorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COLORS: {
            return { ...state, colors: action.payload };
        }
        default:
            return state;
    }
};
