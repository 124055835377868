import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.scss';

export const GarmentLink = (props) => {
    const newProps = { ...props };
    delete newProps.externalLink;
    delete newProps.underlineLink;

    return props.externalLink ? (
        <a href={props.to} rel={props.rel ?? 'external'} {...newProps}>
            {props.children}
        </a>
    ) : (
        <Link
            {...newProps}
            className={props.underlineLink ? 'underline' : ''}
        />
    );
};
