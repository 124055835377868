const SET_USER_STATIONS = 'SET_USER_STATIONS';

const initialState = {
    userStations: [],
};

export const userStationsSelector = (state) => state.stations.userStations;

export const setUserStations = (userStations) => ({
    type: SET_USER_STATIONS,
    payload: userStations,
});

export const StationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_STATIONS: {
            return { ...state, userStations: action.payload };
        }
        default:
            return state;
    }
};
