import React from 'react';
import { toast } from 'react-toastify';
import IntlMessages from 'util/IntlMessages';
import isDirectionRTL from 'util/isDirectionRTL';

const isRTL = isDirectionRTL();
// Function displays message passed to function or a default message otherwise based on message type
const showNotificationMessage = (type, message, delay, onClick = undefined) => {
    if (type === 'success') {
        const successMessageTitle = <div className='text-white'><IntlMessages id='notification.success' /></div>;
        const successMessageDescription = <div>{message || <IntlMessages id='notification.success.unknown' />}</div>;
        const successMessageDelay = delay || 2000;
        return toast.success(
            <div onClick={onClick}>
                {successMessageTitle}
                {successMessageDescription}
            </div>,
            { theme: 'colored', autoClose: successMessageDelay , className: isRTL ? 'arabicFont' : 'englishFont'}
        );
    } else if (type === 'error') {
        const errorMessageTitle = <div className='text-white'><IntlMessages id='notification.error' /></div>;
        const errorMessageDescription = <div>{message || <IntlMessages id='notification.error.unknown' />}</div>;
        const errorMessageDelay = delay || 15_000;

        return toast.error(
            <div onClick={onClick}>
                {errorMessageTitle}
                {errorMessageDescription}
            </div>,
            { theme: 'colored', autoClose: errorMessageDelay, className: isRTL ? 'arabicFont' : 'englishFont'}
        );
    }
};

export default showNotificationMessage;

export const getErrorMessageByStatus = (status) => {
    switch (status) {
        case 400:
            return 'shared.error.400';
        case 404:
            return 'shared.error.404';
        case 500:
        case 502:
            return 'shared.error.500';
        default:
            return status;
    }
};

export const getErrorMessage = (message) => {
    switch (message) {
        case 'no company found':
            return 'shared.error.r01';
        case 'Authentication failed. User not found':
            return 'shared.error.r03';
        case 'Authentication failed. Wrong password':
        case 'Authenticating failed':
            return 'shared.error.r05';
        case 'You don\'t have any permissions':
            return 'shared.error.r06';
        case 'No token provided.':
            return 'shared.error.unknown';
        case 'Display not found':
            return 'shared.error.400';
        default:
            return 'shared.error.unknown';
    }
};

export const displayErrorMessage = (error, showNotification = true) => {
    let errorMessageKey;

    if (error.response && (error.response.data || error.response.status)) {
        if (error.response.data && error.response.data.error) {
            errorMessageKey = getErrorMessage(error.response.data.error);
        } else {
            errorMessageKey = getErrorMessageByStatus(error.response.status);
        }
    }

    let errorMessage;

    if (errorMessageKey) {
        errorMessage = <IntlMessages id={errorMessageKey} />;
    } else {
        errorMessage = error.message ? error.message : '';
    }

    if (showNotification) {
        showNotificationMessage('error', errorMessage);
    }

    return errorMessage;
};
