/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { TreeSelect } from 'antd';
import { isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import SectionTagsAPI from 'app/modules/Production/SectionTags/SectionTagsAPI';

import { setAllSectionsTags, setScopeSectionsTags } from 'reducers/SectionTags';

import { SET_FACTORY_SCOPE } from '../../../../../reducers/Scope';
import IntlMessages from '../../../../../util/IntlMessages';
import { apiRequest, handlePromiseError } from '../../api/apiRequest';
import { useAuthuser } from '../../hooks/useAuthuser';
import { useSettings } from '../../hooks/useSettings';
import { formulateFactoryData } from '../../util/formulateFactoryData';
import { SectionTag } from '../SectionTag/SectionTag';

import { SectionsTreeSelectionAPI } from './SectionsTreeSelectionAPI';

export const getUserScope = async (company, factory) => {
    try {
        const { data } = await apiRequest(
            SectionsTreeSelectionAPI.getProductionLinesAndSections,
            company,
            factory
        );
        return formulateFactoryData(data);
    } catch (e) {
        handlePromiseError(e);
    }
};

export const SectionsTreeSelection = ({
    sections,
    setSections,
    selectAllByDefault = false,
    showSelectionInline = true,
    shouldFetch = true,
    showErrorIfEmpty = true,
    showButton = true,
    disabled = false,
    setIsAllSectionsSelected,
    externalTags,
    externalFactory,
    allExternalSections,
}) => {
    const [tempSelection, setTempSelection] = useState([...sections]);
    const [sectionsTags, setSectionsTags] = useState([]);
    const { isDirectionRTL: rtl } = useSettings();

    const dispatch = useDispatch();
    const { company, factory: userFactory } = useAuthuser();
    const factoryData = useSelector((state) => state.scope.factoryData);
    const { scopeSectionsTags: storeScopeSectionsTags, allSectionsTags } =
        useSelector((state) => state.sectionTagsData);

    let factory, allSectionsIds;
    if (factoryData) {
        factory = externalFactory
            ? { ...externalFactory, value: 'allLinesAndSections' }
            : { ...factoryData.factory, value: 'factory' };
        allSectionsIds = factoryData.allSectionsIds;
    }
    const scopeSectionsTags = externalTags || storeScopeSectionsTags;
    const count = useMemo(() => {
        if (!factory) return 0;
        return factory.children.reduce(
            (acc, line) => acc + (line.children ? line.children?.length : 0),
            0,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factoryData]);

    // Check if tags' sections are in the selected sections (so the tag should be selected) or not (so the tag should not be selected)
    const isTagSelected = (tagSections, selectedSections) =>
        tagSections.every((section) => selectedSections.includes(section));

    // Formulate sections' tags, and show only tags that are associated with user's sections
    const formulateSectionTags = (allTags, scopeTags) => {
        const formulatedTags = [];
        allTags.forEach((tag) => {
            scopeTags.forEach((sectionTag) => {
                const tagId = Object.keys(sectionTag)[0];
                const sectionsIds = Object.values(sectionTag)[0];

                if (tagId === tag._id) {
                    formulatedTags.push({
                        ...tag,
                        selected: isTagSelected(sectionsIds, tempSelection),
                        sections: sectionsIds,
                    });
                }
            });
        });
        return formulatedTags;
    };

    // Handle the chenge of section tag selection or de-selection
    const tagsChangeHandler = (tagId) => {
        const selectedTag = sectionsTags.find((tag) => tag._id === tagId);
        setSectionsTags(
            sectionsTags.map((tag) =>
                selectedTag._id === tag._id
                    ? { ...tag, selected: !selectedTag.selected }
                    : tag,
            ),
        );
        let newSections;
        if (selectedTag.selected) {
            // In this case, the tag is already selected, and user wants to de-select it.
            // So we need to remove its sections from the tree
            const sectionsToBeDeselected = new Set(selectedTag.sections);
            newSections = tempSelection.filter(
                (section) => !sectionsToBeDeselected.has(section),
            );
        } else {
            // In this case, the tag is not selected, and user wants to select it
            // so we need to add its sections to the tree
            newSections = [...tempSelection, ...selectedTag.sections];
        }
        setTempSelection(newSections);
        !showButton && setSections(newSections);
    };

    useEffect(() => {
        if (!factory && shouldFetch) {
            getUserScope(company, userFactory)
                .then(({ factory, allSections, tagsWithSections }) => {
                    const payload = {
                        factory,
                        allSections,
                    };
                    dispatch({ type: SET_FACTORY_SCOPE, payload });
                    dispatch(setScopeSectionsTags(tagsWithSections));
                    if (selectAllByDefault) {
                        setTempSelection(allSections);
                        setSections(allSections);
                    }
                })
                .catch(handlePromiseError);
        } else if (allSectionsIds && selectAllByDefault) {
            setSections(allSectionsIds, true);
            setTempSelection(allSectionsIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factoryData]);

    useEffect(() => {
        if (setIsAllSectionsSelected) {
            if (allExternalSections) {
                setIsAllSectionsSelected(
                    allExternalSections?.length === sections?.length &&
                        allExternalSections.every((id) =>
                            sections.includes(id),
                        ),
                );
            } else {
                setIsAllSectionsSelected(
                    allSectionsIds?.length === sections?.length &&
                        allSectionsIds.every((id) => sections.includes(id)),
                );
            }
        }
        setTempSelection(sections);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections, allExternalSections]);

    useEffect(() => {
        if (scopeSectionsTags) {
            if (allSectionsTags !== null) {
                setSectionsTags(
                    formulateSectionTags(allSectionsTags, scopeSectionsTags),
                );
            } else {
                apiRequest(SectionTagsAPI.getAll, company, userFactory)
                    .then(({ data }) => {
                        dispatch(setAllSectionsTags(data.tags));
                        setSectionsTags(
                            formulateSectionTags(data.tags, scopeSectionsTags),
                        );
                    })
                    .catch(handlePromiseError);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections, tempSelection, scopeSectionsTags]);

    const treeRef = useRef();

    const treeConfigurations = {
        treeData: factory ? [factory] : [],
        value: factory && tempSelection?.length ? tempSelection : [],
        disabled: disabled,
        onChange: (event) => {
            if (!showSelectionInline) {
                setTempSelection(event);
            } else if (!isEqual(event, sections)) {
                setSections(event, count === event?.length);
                setTempSelection(event);
            }
        },
        onBlur: (event) => {
            if (
                !showButton &&
                !isEqual(tempSelection, sections) &&
                !showSelectionInline
            ) {
                setSections(tempSelection, count === tempSelection?.length);
            }
        },
        maxTagCount: showSelectionInline ? count : 0,
        maxTagPlaceholder: !showSelectionInline && (
            <span>
                <IntlMessages id='quality.inlineQualitySupervisorPerformanceReport.selectSections' />
                &nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </span>
        ),
        treeCheckable: true,
        style:
            tempSelection?.length === 0 && showErrorIfEmpty
                ? { width: '100%', border: '1px solid red' }
                : { width: '100%' },
        multiple: true,
        switcherIcon: (
            <FontAwesomeIcon
                className='ml-2'
                icon={rtl ? faChevronLeft : faChevronRight}
            />
        ),
        placeholder: (
            <IntlMessages id='quality.inlineQualitySupervisorPerformanceReport.selectSections' />
        ),
        ref: treeRef,
        treeDefaultExpandAll: true,
        dropdownStyle: { zIndex: 1500 },
        dropdownRender: (originNode, dropDownProps) => {
            return (
                <>
                    {sectionsTags?.length !== 0 && (
                        <>
                            {sectionsTags.map((tag, index) => (
                                <span
                                    key={index}
                                    onClick={() => tagsChangeHandler(tag._id)}
                                >
                                    <SectionTag
                                        tag={tag}
                                        selected={tag.selected}
                                    />
                                </span>
                            ))}
                            <hr />
                        </>
                    )}
                    {originNode}
                    {showButton && (
                        <div className='w-100 text-right'>
                            <Button
                                variant='contained'
                                color='primary'
                                className='my-2 mr-4'
                                onClick={() => {
                                    setSections(
                                        tempSelection,
                                        count === tempSelection?.length,
                                    );
                                    treeRef.current.focus();
                                    setTimeout(() => {
                                        treeRef.current.blur();
                                    }, 90);
                                }}
                            >
                                <IntlMessages id='button.agree' />
                            </Button>
                        </div>
                    )}
                </>
            );
        },
    };

    return <TreeSelect {...treeConfigurations} />;
};
