import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import CachedIcon from '@material-ui/icons/Cached';
import IntlMessages from 'util/IntlMessages';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons//Close';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { OfflineInspectionsContent } from './OfflineInspectionsContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './OfflineInspectionsContent.module.scss';
import { inlineInspectionsSelector } from '../../../../../../reducers/InlineInspections';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef((props, ref) => (
    <Slide direction='up' {...props} ref={ref} />
));
Transition.displayName = 'Transition';

export const OfflineInlineInspections = () => {
    const inspections = useSelector(inlineInspectionsSelector);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const mq = useMediaQuery(`(max-width: ${styles.maxWidth})`);

    const title = (
        <>
            <strong className='mr-2'>
                ({inspections ? inspections.length : 0})
            </strong>
            <IntlMessages id='shared.offlineInspections' />
        </>
    );

    const onClose = () => setIsModalOpen(false);

    return (
        <>
            <IconButton id='cached' className='p-2'>
                <Badge
                    badgeContent={inspections && inspections.length}
                    invisible={!inspections || inspections.length === 0}
                    color='primary'
                    className='text-white'
                    onClick={() => setIsModalOpen(true)}
                >
                    <CachedIcon />
                </Badge>
            </IconButton>

            {isModalOpen && (
                <Dialog
                    fullScreen={!!mq}
                    maxWidth={!mq ? 'md' : false}
                    fullWidth={true}
                    open={true}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    scroll={mq ? 'body' : 'paper'}
                >
                    {mq ? (
                        <AppBar className='position-relative bg-primary-color'>
                            <Toolbar>
                                <IconButton
                                    color='inherit'
                                    onClick={onClose}
                                    aria-label='Close'
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='subtitle1' color='inherit'>
                                    {title}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    ) : (
                        <DialogTitle>{title}</DialogTitle>
                    )}
                    <OfflineInspectionsContent />
                </Dialog>
            )}
        </>
    );
};
