export const SET_FACTORY_SCOPE = 'SET_FACTORY_SCOPE';

const initialState = { factory: null };

export const setFactoryScope = (factoryData) => {
    return {
        type: SET_FACTORY_SCOPE,
        payload: factoryData,
    };
};

export const ScopeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FACTORY_SCOPE:
            return {
                ...state,
                factoryData: {
                    factory: action.payload.factory,
                    allSectionsIds: action.payload.allSections,
                },
            };
        default:
            return state;
    }
};
