const SET_LIVE_TILE_DATA = 'SET_LIVE_TILE_DATA';
const UPDATE_LIVE_TILE_DATA = 'UPDATE_LIVE_TILE_DATA';

const initialState = { tileData: {} };

/**
 * @param processId {string}
 * @param wipPieces {number}
 * @param productivityLastHour {number}
 */
export const updateLiveTileData = (
    processId,
    wipPieces,
    lastHourProductivity
) => ({
    type: UPDATE_LIVE_TILE_DATA,
    payload: { processId, wipPieces, lastHourProductivity },
});

export const setLiveTileData = (liveTileData) => ({
    type: SET_LIVE_TILE_DATA,
    payload: liveTileData,
});

export const SupervisorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LIVE_TILE_DATA:
            return { ...state, tileData: action.payload };
        case UPDATE_LIVE_TILE_DATA: {
            const { processId, wipPieces, lastHourProductivity } =
                action.payload;
            const { tileData = {}, ...rest } = state;

            const newTileData = {
                ...tileData,
                [processId]: { wipPieces, lastHourProductivity },
            };
            return { ...rest, tileData: newTileData };
        }
        default:
            return state;
    }
};
