import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import React from 'react';
import styles from './NotificationItem.module.scss';

const NotificationItem = ({
    notification,
    onDeleteNotification,
    onClickNotification,
    swipe,
}) => {
    const {
        isRead,
        notification: { title, body, url, createdAt, _id, icon, color },
    } = notification;

    return (
        <>
            {swipe ? (
                <ListItem
                    onClick={() => onClickNotification(_id, url)}
                    className={`${styles.item} ${
                        isRead ? styles.readBackground : ''
                    }`}
                    button
                    divider
                >
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: color }}>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <div>
                        <ListItemText
                            className={`${isRead ? '' : styles.bold}`}
                            primary={title}
                            secondary={body}
                        />
                        <ListItemText
                            secondary={moment(createdAt).format(
                                'YYYY/MM/DD - hh:mm A'
                            )}
                        />
                    </div>
                </ListItem>
            ) : (
                <ListItem
                    onClick={() => onClickNotification(_id, url)}
                    className={`${styles.item} ${
                        isRead ? styles.readBackground : ''
                    }`}
                    button
                >
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: color }}>
                            {icon}
                        </Avatar>
                    </ListItemAvatar>
                    <div>
                        <ListItemText
                            className={`${isRead ? '' : styles.bold}`}
                            primary={title}
                            secondary={body}
                        />
                        <ListItemText
                            secondary={moment(createdAt).format(
                                'YYYY/MM/DD - hh:mm A'
                            )}
                        />
                    </div>
                    <div className={styles.deleteButtonContainer}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onDeleteNotification(_id);
                            }}
                            className={styles.deleteButton}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </ListItem>
            )}
        </>
    );
};

export default NotificationItem;
