const lang = localStorage.getItem("lang") && JSON.parse(localStorage.getItem("lang")).locale

export default {
    palette: {
        primary: {
            light: '#68c4ab',
            main: '#58bea2',
            dark: '#3d8571',
            contrastText: '#fff'
        },
        secondary: {
            light: '#51aab4',
            main: '#2695a2',
            dark: '#1a6871',
            contrastText: '#fff'
        },
        error: {
            light: '#FF5722',
            main: '#FF5722',
            dark: '#FF5722',
            contrastText: '#fff',
        }
    },
    status: {
        danger: 'orange',
    },
    typography: {
        button: {
            fontWeight: 400,
            textAlign: 'capitalize'
        },
        fontFamily: [ !lang? "Cairo":lang==="ar"? "Cairo": 'Roboto', 'sans-serif'].join(',')
    }
};
