import { createSelector } from 'reselect';

const SET_STYLE_DEPENDENCY_MAP = 'SET_DEPENDENCY_MAP';
const SET_STAGE_PROCESSES = 'SET_STAGE_PROCESSES';

const initialState = { dependencies: {}, processes: [] };

const styleDependenciesSelector = (state) =>
    state.styleDependencies.dependencies;
const stageProcessesSelector = (state) => state.styleDependencies.processes;

/**
 * @param dependencies {Object.<string, Set<string>>}
 * @param processes {string[]}
 * @returns {string[]}
 */
const resolveDependencies = (dependencies, processes) => {
    /** @type {Set<string>[]} */
    const dependencySets = Object.values(dependencies);

    if (processes.length === 0) return [];

    // keep mandatory childless processes that are orphaned
    return processes
        .filter((id) => dependencies[id].size === 0)
        .filter((id) => !dependencySets.filter((set) => set.has(id)).length);
};

export const independentProcessesSelector = createSelector(
    styleDependenciesSelector,
    stageProcessesSelector,
    resolveDependencies
);

export const setStageProcesses = (processes) => ({
    type: SET_STAGE_PROCESSES,
    payload: processes,
});

/**
 * @param dependencies {Object.<string, Set<string>>}
 * @return {{payload: *, type: string}}
 */
export const setStyleDependencies = (dependencies) => ({
    type: SET_STYLE_DEPENDENCY_MAP,
    payload: dependencies,
});

export const StyleDependenciesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STYLE_DEPENDENCY_MAP:
            return { ...state, dependencies: action.payload };
        case SET_STAGE_PROCESSES:
            return { ...state, processes: action.payload };

        default:
            return state;
    }
};
