import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_CUTTING_ORDER,
    setCuttingOrder,
    setIsLoading,
} from 'reducers/Cutting/CuttingOrder';
import { isTokenValid } from '../app/modules/shared/util/tokenValidation';
import AddEditCuttingOrderAPI from '../app/modules/Cutting/AddEditCuttingOrder/AddEditCuttingOrderAPI';
import { markerFormatter } from '../app/modules/shared/util/markerFormatter';
import { errorHandlerNewPattern } from '../app/modules/shared/util/globalErrorHandler';

const sendCuttingOrderRequest = (company, factory, id) =>
    AddEditCuttingOrderAPI.getCuttingOrder(company, factory, id);

function* getCuttingOrderRequest(event) {
    try {
        const { company, factory, cuttingOrderId } = event.payload;
        const response = yield call(
            sendCuttingOrderRequest,
            company,
            factory,
            cuttingOrderId
        );

        if (!isTokenValid(response)) return;

        yield put(
            setCuttingOrder({
                ...response.data.data,
                marker: markerFormatter(response.data.data.marker),
            })
        );
    } catch (error) {
        errorHandlerNewPattern(error);
        yield put(setIsLoading(false));
    }
}

export function* getCuttingOrder() {
    yield takeEvery(GET_CUTTING_ORDER, getCuttingOrderRequest);
}

export default function* rootSaga() {
    yield all([fork(getCuttingOrder)]);
}
