import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setScopeSectionsTags } from 'reducers/SectionTags';

import { SET_FACTORY_SCOPE } from '../../../../reducers/Scope';
import { getUserScope } from '../components/SectionsTreeSelection/SectionsTreeSelection';
import { formulateFactoryData } from '../util/formulateFactoryData';

import { useAuthuser } from './useAuthuser';

export const useScope = (reportName, onChange, externalData) => {
    const factoryData = useSelector((state) => state.scope.factoryData);
    const [checkLocalStorage, setCheckLocalStorage] = useState(false);
    const [isScopeLoading, setIsScopeLoading] = useState(true);
    const { company, factory: userFactory } = useAuthuser();
    const dispatch = useDispatch();
    const [externalFactory, setExternalFactory] = useState(null);
    const [externalTags, setExternalTags] = useState(null);

    const key = reportName ? `${reportName}Scope` : null;

    const updateUrl = (scope) => {
        const urlParams = new URLSearchParams(window.location.search);

        const sectionIds = scope.join(',');
        if (sectionIds.length > 0) {
            urlParams.set('sectionId', sectionIds);
            key && localStorage.setItem(key, JSON.stringify(scope));
        } else {
            urlParams.delete('sectionId');
            key && localStorage.removeItem(key);
        }

        const url = new URL(window.location.href);
        url.search = urlParams.toString().replaceAll('%2C', ',');
        window.history.pushState(null, '', url.toString());
    };

    const [sections, setSections] = useState([]);
    const [allExternalSections, setAllExternalSections] = useState([]);

    useEffect(() => {
        if (!externalData) return;

        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('sectionId');
        const { factory, allSections, tagsWithSections } = formulateFactoryData(
            {
                data: { productionLinesAndSections: externalData },
            }
        );
        setSections(allSections);
        setAllExternalSections(allSections);
        setExternalFactory(factory);
        setExternalTags(tagsWithSections);
    }, [externalData]);

    useEffect(() => {
        if (!factoryData && !externalData) {
            getUserScope(company, userFactory)
                .then(({ factory, allSections, tagsWithSections }) => {
                    const payload = {
                        factory,
                        allSections,
                    };
                    dispatch({ type: SET_FACTORY_SCOPE, payload });
                    dispatch(setScopeSectionsTags(tagsWithSections));
                })
                .finally(() => setIsScopeLoading(false));
        } else {
            setIsScopeLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalData]);

    useEffect(() => {
        if (factoryData && !externalData && !checkLocalStorage) {
            const urlParams = new URLSearchParams(window.location.search);
            const urlSections = urlParams.get('sectionId');
            const urlSectionsArray = urlSections ? urlSections.split(',') : [];

            if (urlSectionsArray.length > 0) {
                if (key)
                    localStorage.setItem(key, JSON.stringify(urlSectionsArray));
                setSections([...urlSectionsArray]);
            } else {
                const storedScope = localStorage.getItem(key);
                const scope = storedScope ? JSON.parse(storedScope) : [];
                const filteredSections = scope.filter((s) =>
                    factoryData.allSectionsIds.includes(s)
                );
                setSections([...filteredSections]);
            }
            setCheckLocalStorage(true);
            return;
        }
        if (factoryData && !externalData) {
            updateUrl(sections);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factoryData, sections, externalData]);

    const handleSectionsChange = useCallback(
        (scope) => {
            setSections(scope);
            if (onChange) onChange(scope);
        },
        [onChange]
    );

    return {
        sections: externalData ? [...new Set(sections)] : sections,
        setSections,
        handleSectionsChange,
        isScopeLoading,
        externalTags,
        externalFactory,
        allExternalSections: [...new Set(allExternalSections)],
    };
};
