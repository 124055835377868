import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { openLink } from '../shared/util/openLink';
import IntlMessages from 'util/IntlMessages';

export default class AccessDenied extends Component {
    render = () => {
        return (
            <div className='mt-5'>
                <Typography align='center' variant='h2' gutterBottom>
                    403
                </Typography>
                <Typography align='center' variant='h3' gutterBottom>
                    <IntlMessages id='403.title' />
                </Typography>
                <Typography align='center' variant='h5' gutterBottom>
                    <IntlMessages id='403.body1' />

                    <br />
                    <IntlMessages id='403.body2' />
                </Typography>
                <br />

                <div className='text-center'>
                    <Button
                        onClick={() =>
                            openLink('/', {
                                ctrlKey: false,
                            })
                        }
                        color='primary'
                    >
                        <IntlMessages id='403.button' />
                    </Button>
                </div>
            </div>
        );
    };
}
