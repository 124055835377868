import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    GET_TRANSLATIONS_FAILED,
    GET_TRANSLATIONS_FETCH,
    GET_TRANSLATIONS_SUCCESS,
} from 'constants/ActionTypes';

import { isTokenValid } from '../app/modules/shared/util/tokenValidation';
import { TranslationAPI } from 'app/modules/CompanyManagement/Translations/TranslationAPI';

const reformulateTranslations = ({ translations, version }) => {
    const en = {};
    const ar = {};
    const fr = {};
    const enTooltip = {};
    const arTooltip = {};
    const frTooltip = {};
    translations.forEach((item) => {
        en[item.key] = item.value.en;
        ar[item.key] = item.value.ar;
        fr[item.key] = item.value.fr;
        enTooltip[item.key] = item.tooltip.en;
        arTooltip[item.key] = item.tooltip.ar;
        frTooltip[item.key] = item.tooltip.fr;
    });
    return {
        en,
        ar,
        fr,
        enTooltip,
        arTooltip,
        frTooltip,
        version,
    };
};

function* getTranslationRequest(action) {
    try {
        const response = yield call(
            TranslationAPI.getTranslations,
            action.payload
        );

        if (!isTokenValid(response)) return;

        const formulatedTranslations = reformulateTranslations(
            response.data.data
        );
        localStorage.setItem(
            'translations',
            JSON.stringify(formulatedTranslations, null, 2)
        );

        yield put({
            type: GET_TRANSLATIONS_SUCCESS,
            translations: formulatedTranslations,
        });
    } catch (error) {
        yield put({ type: GET_TRANSLATIONS_FAILED, message: error.message });
    }
}

export function* getTranslation() {
    yield takeEvery(GET_TRANSLATIONS_FETCH, getTranslationRequest);
}
export default function* rootSaga() {
    yield all([fork(getTranslation)]);
}
