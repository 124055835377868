import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Layout from '../Layout/Layout';

class LayoutRoute extends Component {
    render = () => {
        return (
            <Route
                {...this.props}
                render={(props) => (
                    <Layout {...props} layout={this.props.layout}>
                        {this.props.compt}
                    </Layout>
                )}
            />
        );
    };
}

const mapStateToProps = ({ auth: { authUser } }) => ({ authUser });

export default connect(mapStateToProps)(LayoutRoute);
