import IntlMessages from 'util/IntlMessages';
import { errorHandlerNewPattern } from '../util/globalErrorHandler';
import showNotificationMessage from '../util/NotificationMessage';
import { isTokenValid } from '../util/tokenValidation';
import React from 'react';

export async function apiRequest(request, ...params) {
    const lastParam = params[params.length - 1];
    const errorMessageFlag =
        typeof lastParam === 'object' &&
        lastParam?.showErrorMessage !== undefined;
    if (errorMessageFlag) {
        params.pop();
    }

    try {
        const response = await request(...params);

        if (!isTokenValid(response)) return;

        if (response.data.status === false) {
            showNotificationMessage(
                'error',
                response.data.code ? (
                    <IntlMessages id={`shared.error.${response.data.code}`} />
                ) : null
            );

            return Promise.reject(response);
        }

        return Promise.resolve(response);
    } catch (error) {
        if (!errorMessageFlag || lastParam.showErrorMessage)
            errorHandlerNewPattern(error);
        return Promise.reject(error);
    }
}

export function handlePromiseError(error) {
    console.error('An error occurred:', error);
}
