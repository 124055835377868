import axiosInstance from '../../shared/api/axiosInstance';

const AddEditCuttingOrderAPI = {
    addCuttingOrder: (company, factory, cuttingOrderData) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/addCuttingOrder`,
            {
                ...cuttingOrderData,
            }
        ),

    editCuttingOrder: (company, factory, cuttingOrderData) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/editCuttingOrder`,
            {
                ...cuttingOrderData,
            }
        ),

    getMarkersByPOs: (company, factory, productionOrderId) =>
        axiosInstance.get(
            `${company}/${factory}/markers?productionOrderId=${productionOrderId}`
        ),

    getRollsByPoClient: (company, factory, productionOrderId, cuttingOrderId) =>
        axiosInstance.get(
            `${company}/${factory}/rolls/getRollsForPoClient?productionOrderId=${productionOrderId}${
                cuttingOrderId ? '&cuttingOrderId=' + cuttingOrderId : ''
            }`
        ),

    getCuttingOrder: (company, factory, cuttingOrderId) =>
        axiosInstance.get(
            `${company}/${factory}/cuttingOrders/${cuttingOrderId}`
        ),

    getEstimatedQuantityData: (company, factory, productionOrder) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/estimatedCutData`,
            {
                productionOrder,
            }
        ),

    addAutomaticBatches: (company, factory, cuttingOrderId, batches) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/${cuttingOrderId}/batches`,
            {
                batches,
            }
        ),

    getNextCuttingOrderNumber: (company, factory, productionOrderId) =>
        axiosInstance.post(
            `${company}/${factory}/cuttingOrders/nextCuttingOrder`,
            {
                productionOrderId,
            }
        ),

    getCuttingEnabledPOs: (company, factory, statuses) =>
        axiosInstance.post(
            `${company}/${factory}/orders/listEnabledCOsProductionOrders`,
            { statuses }
        ),
};

export default AddEditCuttingOrderAPI;
