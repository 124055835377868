import React from 'react';

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_NOTIFICATIONS, PROCESS_NOTIFICATION } from 'constants/ActionTypes';

import { showAuthMessage } from 'actions/Auth';

import {
    addNotification,
    getNotificationsSuccess,
} from 'actions/Notifications';

import moment from 'moment';

import {
    faBell,
    faDiagnoses,
    faExclamationCircle,
    faTimesCircle,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import getTranslation from '../app/modules/shared/util/Language';
import { isTokenValid } from '../app/modules/shared/util/tokenValidation';

import NotificationsAPI from '../app/modules/shared/api/NotificationsAPI';

import styles from '../app/modules/shared/styles/theme.js';

const NotificationsApi = new NotificationsAPI();

const sendNotificationsRequest = (slice) =>
    NotificationsApi.getNotifications(slice);

const getNotificationIcon = (eventType) => {
    switch (eventType) {
        case 'maintenance':
            return {
                icon: <FontAwesomeIcon icon={faWrench} />,
                color: styles.warningColor,
            };
        case 'dependancyViolation':
            return {
                icon: <FontAwesomeIcon icon={faExclamationCircle} />,
                color: styles.dangerColor,
            };
        case 'noActiveShift':
            return {
                icon: <FontAwesomeIcon icon={faTimesCircle} />,
                color: styles.dangerColor,
            };
        case 'bottleneck':
            return {
                icon: <FontAwesomeIcon icon={faExclamationCircle} />,
                color: styles.dangerColor,
            };
        case 'inlineQualityIssue':
            return {
                icon: <FontAwesomeIcon icon={faDiagnoses} />,
                color: styles.darkerColor,
            };
        case 'qualityStationDefects':
            return {
                icon: <FontAwesomeIcon icon={faDiagnoses} />,
                color: styles.darkerColor,
            };
        case 'qualityStationRepairs':
            return {
                icon: <FontAwesomeIcon icon={faDiagnoses} />,
                color: styles.darkerColor,
            };
        default:
            return { icon: <FontAwesomeIcon icon={faBell} />, color: null };
    }
};

function* getUserNotificationsRequest(event) {
    try {
        // loading notifications

        const response = yield call(sendNotificationsRequest, event.payload);

        if (!isTokenValid(response)) return;

        const notifications = response.data.notifications
            .map((notification) => ({
                ...notification,
                notification: {
                    ...notification.notification,
                    ...getNotificationIcon(notification.notification.eventType),
                },
            }))
            .sort((first, secound) =>
                moment(first.notification.createdAt).isAfter(
                    secound.notification.createdAt,
                )
                    ? -1
                    : 1,
            );

        const payload = {
            notifications,
            notificationsLastReadDate: response.data.notificationsLastReadDate,
            totalNotifications: response.data.totalNotifications,
        };

        yield put(getNotificationsSuccess(payload));
    } catch (error) {
        //this.setState({ isNotificationsLoading: false });
        yield put(showAuthMessage(getTranslation(error.message)));
    }
}

function* processNotification(payload) {
    const audio = new Audio('/notification.mp3');
    audio.play();

    const newNotification = {
        isRead: false,
        notification: {
            ...payload.payload,
            ...getNotificationIcon(payload.payload.eventType),
        },
    };
    yield put(addNotification(newNotification));
}

export function* getUserNotifications() {
    yield takeEvery(GET_NOTIFICATIONS, getUserNotificationsRequest);
}

export function* processNewNotification() {
    yield takeEvery(PROCESS_NOTIFICATION, processNotification);
}

export default function* rootSaga() {
    yield all([fork(getUserNotifications), fork(processNewNotification)]);
}
