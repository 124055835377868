import { history } from '../../../../store';

const openLink_ = (url, event, state = {}) => {
    if (event.ctrlKey) {
        window.open(url, '_blank');
    } else {
        history.push(url, state);
    }
};
export const openLink = (link, event, state) => {
    if (!event) {
        return openLink_(link, { ctrlKey: true });
    }
    return openLink_(link, event, state);
};
