import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { redirectToLandingPage } from '../../shared/util/redirectToLandingPage';

class NotAuthUserRoute extends Component {
    state = {
        authUser: null,
    };

    componentDidMount = () => {
        let authUser = localStorage.getItem('user_id');
        authUser = authUser && JSON.parse(authUser);

        this.setState({ authUser });
        redirectToLandingPage(authUser);
    };

    render = () => {
        return (
            <Route
                {...this.props}
                render={(props) =>
                    !this.state.authUser ? (
                        <Layout {...props} layout={this.props.layout}>
                            {this.props.compt}
                        </Layout>
                    ) : null
                }
            />
        );
    };
}

export default NotAuthUserRoute;
