import { useState, useEffect } from 'react';

export const useIsNetworkOnline = () => {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const updateNetwork = () => {
       setIsOnline(window.navigator.onLine);
    };
    useEffect(() => {
       window.addEventListener('offline', updateNetwork);
       window.addEventListener('online', updateNetwork);
       return () => {
          window.removeEventListener('offline', updateNetwork);
          window.removeEventListener('online', updateNetwork);
       };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.navigator.onLine]);
    return isOnline;
};