import axiosInstance from '../../shared/api/axiosInstance';

export const TranslationAPI = {
    isTranslationUpdated: (company, version) =>
        axiosInstance.get(
            `translation/isTranslationUpdated/?currentVersion=${version}&companySlug=${company}`
        ),
    getTranslations: (company) => axiosInstance.get(`${company}/translation`),
    updateTranslation: (company, translationUpdates) =>
        axiosInstance.put(`${company}/translation`, translationUpdates),
    restoreDefaultTranslation: (company, key) =>
        axiosInstance.delete(`${company}/translation`, { data: { key } }),
};
