import Bowser from 'bowser';

export const getBrowserInfo = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowser(); // {name: 'Chrome', version: '124.0.0.0'} 
};

export const detectBrowser = () => {
    let info = getBrowserInfo();
    return info.name || 'unknown';
};

