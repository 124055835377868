const SET_SCOPE_SECTIONS_TAGS = 'SET_SCOPE_SECTIONS_TAGS';
const SET_ALL_SECTIONS_TAGS = 'SET_ALL_SECTIONS_TAGS';

const initialState = { 
    scopeSectionsTags: null,
    allSectionsTags: null,
};

export const setScopeSectionsTags = (tags) => {
    return {
        type: SET_SCOPE_SECTIONS_TAGS,
        payload: tags,
    };
};

export const setAllSectionsTags = (allTags) => {
    return {
        type: SET_ALL_SECTIONS_TAGS,
        payload: allTags,
    };
};

export const SectionTagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SCOPE_SECTIONS_TAGS:
            return {
                ...state,
                scopeSectionsTags: action.payload,
            };
        case SET_ALL_SECTIONS_TAGS:
            return {
                ...state,
                allSectionsTags: action.payload,
            };
        default:
            return state;
    }
};

