const getUserPermissions = (needFullPermissions = false, authUser) => {
    // Get A flattened array of the permissions associated with every role of the user
    if (needFullPermissions) {
        return authUser.roles
            .map((role) => role.permissions)
            .reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
            );
    } else {
        return authUser.roles
            .map((role) =>
                role.permissions.map((permission) => permission.name)
            )
            .reduce(
                (accumulator, currentValue) => accumulator.concat(currentValue),
                []
            );
    }
};

export default getUserPermissions;

export const getPagePermissions = (page, permissions) => {
    return permissions.filter((permission) => permission.page === page);
};

export const getPermission = (permissions, permissionName) => {
    return permissions.find((perm) => perm.name === permissionName);
};
