export const orderStyleFormatter = (orderStyles) => {
    const formattedData = {};
    const sizeQuantities = {};
    const poSizes = {};
    const poColors = {};

    orderStyles.forEach((item) => {
        formattedData[item.color._id] = {
            ...formattedData[item.color._id],
            ...item.color,
            quantity:
                (formattedData[item.color._id]?.quantity || 0) + item.quantity,
            sizes: {
                ...formattedData[item.color._id]?.sizes,
                [item.size._id]: {
                    ...item.size,
                    quantity: item.quantity,
                },
            },
        };
        sizeQuantities[item.size._id] =
            (sizeQuantities[item.size._id] || 0) + item.quantity;
        if (!poSizes[item.size._id]) poSizes[item.size._id] = item.size;
        if (!poColors[item.color._id]) poColors[item.color._id] = item.color;
    });

    return { formattedData, sizeQuantities, poSizes, poColors };
};

export const poDataColorsSizes = (
    orderStyles,
    orderStylesProperty = 'order_styles'
) => {
    if (!orderStyles) return;

    const { formattedData, sizeQuantities, poColors, poSizes } =
        orderStyleFormatter(orderStyles[orderStylesProperty]);

    const poData = {
        formattedData,
        sizeQuantities,
        ...orderStyles,
    };

    const sizesArray = Object.values(poSizes).sort((a, b) => a.order - b.order);
    const colorsArray = Object.keys(poColors).map((colorId) => ({
        _id: colorId,
        name: poColors[colorId].name,
    }));

    return { poData, sizesArray, colorsArray };
};
