import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryAutomateBeatsEnabled = () => {
    try {
        return !!JSON.parse(localStorage.user_id)?.factoryData
            ?.isAutomateBeats;
    } catch (e) {
        return false;
    }
};

/** @returns {boolean} */
export const useHasAutomateBeats = () => 
    useSelector(({ settings }) => {
        return settings.factoryAutomateBeats && settings.factoryAutomateBeats;
});

