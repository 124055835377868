import { countBy, sumBy, sum } from 'lodash-es';
import { createSelector } from 'reselect';

const SET_DEFECT_GROUPS = 'SET_DEFECT_GROUPS';
const DEC_DEFECTED_PIECES = 'DEC_DEFECTED_PIECES';
const INC_DEFECTED_PIECES = 'INC_DEFECTED_PIECES';

export const defectsCountSelector = (groupName, defectId) => (state) =>
    state.aql?.defectsGroup?.[groupName]?.[defectId]?.length || 0;

const defectGroupSelector = (state) => state.aql?.defectsGroup;

export const defectLevelsSelector = createSelector(
    defectGroupSelector,
    (defectsGroup) => {
        console.log({ defectsGroup });
        const groups = Object.values(defectsGroup);
        if (!groups.length) {
            return { major: 0, minor: 0, critical: 0 };
        }

        const levels = groups
            .flatMap((g) => Object.values(g))
            .flat()
            .map((d) => d.level);

        const { major = 0, minor = 0, critical = 0 } = countBy(levels);

        return { major, minor, critical };
    },
);

export const defectGroupCountSelector = createSelector(
    defectGroupSelector,
    (groups) => {
        const entries = Object.entries(groups).map(([k, v]) => [
            k,
            sumBy(Object.values(v), 'length'),
        ]);

        return Object.fromEntries(entries);
    },
);

export const totalDefectCount = createSelector(defectGroupCountSelector, (g) =>
    sum(Object.values(g)),
);

export const defectedPiecesSelector = createSelector(
    defectGroupSelector,
    (groups) =>
        Object.values(groups)
            .map(Object.values)
            .flat(2)
            .map((p) => ({
                level: p.level.toUpperCase(),
                defect: p.defect,
                process: p.process ?? undefined,
            })),
);

const initialState = {
    defectsGroup: {},
};

export const setDefectGroups = (defectGroups) => ({
    type: SET_DEFECT_GROUPS,
    payload: defectGroups,
});

export const decDefectedPieces = (groupName, id) => ({
    type: DEC_DEFECTED_PIECES,
    payload: { groupName, id },
});

export const incDefectedPieces = (groupName, id, process, level) => ({
    type: INC_DEFECTED_PIECES,
    payload: { groupName, id, process, level },
});

const updateDefects = (state, groupName, id, updater) => {
    const defectsGroup = state.defectsGroup;
    const defects = defectsGroup[groupName][id];

    return {
        ...state,
        defectsGroup: {
            ...defectsGroup,
            [groupName]: {
                ...defectsGroup[groupName],
                [id]: updater(defects),
            },
        },
    };
};

export const AQLReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEFECT_GROUPS: {
            const defectGroupsEntries = action.payload.map((group) => {
                const defectsEntries = group.defects.map((defect) => [
                    defect.id,
                    [],
                ]);

                return [group.name, Object.fromEntries(defectsEntries)];
            });

            const defectsGroup = Object.fromEntries(defectGroupsEntries);
            return { ...state, defectsGroup };
        }
        case INC_DEFECTED_PIECES: {
            const { groupName, id, process, level } = action.payload;
            return updateDefects(state, groupName, id, (defects) => [
                ...defects,
                { level, process, defect: id },
            ]);
        }
        case DEC_DEFECTED_PIECES: {
            const { groupName, id } = action.payload;
            return updateDefects(state, groupName, id, (defects) =>
                defects.slice(0, -1),
            );
        }
        default:
            return state;
    }
};
