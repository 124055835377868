import axiosInstance from '../../../api/axiosInstance';

export const OfflineInlineInspectionsAPI = {
    postReport: (company, factory, inlineQualityInspections) =>
        axiosInstance.post(
            `${company}/${factory}/inlineQualityInspections/offline`,
            {
                inlineQualityInspections,
            }
        ),
};
