import React, { useEffect, useRef, useState } from 'react';
import { useSettings } from '../../hooks/useSettings';
import IntlMessages from 'util/IntlMessages';
import garmentLogo from '../../../../../assets/images/printingLogo.svg';
import { StickerStyle } from './stickerStyle';
import { getPrintStyle } from './getPrintStyle';
import { useHasCutting } from '../../util/cuttingEnabled';
import styles from './EntityLabel.module.scss';

const MAX_CHARS_NUM = {
    COLOR: 30,
    PO: 35,
    STYLE: 40,
};

export const EntityLabel = ({
    type,
    data,
    highlight,
    style,
    onClick,
    onTableRendered = false,
}) => {
    const isCuttingEnabled = useHasCutting();
    const { isDirectionRTL } = useSettings();
    const {
        clientName,
        orderNumber,
        styleName,
        styleCode,
        cuttingOrderNumber,
        number,
        color,
        size,
        quantity,
        batches,
        serial,
        batchGroup,
        rollCodes,
    } = data ?? {};

    const urlParams = new URLSearchParams(window.location.search);
    const printingSeparateLabels =
        urlParams.keys().next().value !== undefined && urlParams.get('width');
    if (printingSeparateLabels) getPrintStyle();

    const tableRef = useRef(null);
    const [showOthersButton, setShowOthersButton] = useState(true);

    const handleShowOthersButton = () => {
        setShowOthersButton(false);
    };

    useEffect(() => {
        if (printingSeparateLabels) {
            document
                .querySelectorAll('style,link[rel="stylesheet"]')
                .forEach((item) => {
                    if (item.id !== 'printing-styles') item.remove();
                });
        }

        const style = document.createElement('style');
        style.innerHTML = StickerStyle;
        document.head.appendChild(style);

        return function cleanup() {
            if (printingSeparateLabels) window.location.reload(true);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (tableRef.current && onTableRendered) {
            onTableRendered(tableRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRef]);

    const verticalSeparatorStyle = {
        borderLeft: '1px dotted',
    };
    const inlinePadding = {
        paddingInlineStart: '.5rem',
    };

    const trimField = (fieldString, maxChars = MAX_CHARS_NUM.COLOR) => {
        if (fieldString.length <= maxChars) {
            return fieldString;
        }
        const suffixCharsNumber = 7;
        const prefix = fieldString.slice(0, maxChars - suffixCharsNumber - 3);
        const suffix = fieldString.slice(
            fieldString.length - suffixCharsNumber,
            fieldString.length
        );

        return prefix + '...' + suffix;
    };
    return (
        <div
            className={`sticker ${isDirectionRTL ? 'rtl' : ''} ${
                printingSeparateLabels ? 'separateLabels' : ''
            }`}
            style={style ?? {}}
            onClick={onClick}
        >
            <table className='singleBatch' ref={tableRef}>
                <tbody>
                    <tr>
                        <td>
                            <IntlMessages id='shared.client' />
                        </td>
                        <td className='px-0 wrap'>
                            {trimField(clientName, 20)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className='d-flex'>
                                <div className='w-50'>
                                    <IntlMessages id='shared.productionOrder.abbreviation' />
                                    <span>{': '}</span>
                                    <span className='font-weight-bold wrap'>
                                        {trimField(
                                            orderNumber,
                                            MAX_CHARS_NUM.PO
                                        )}
                                    </span>
                                </div>
                                <span style={verticalSeparatorStyle} />
                                <div className='w-50' style={inlinePadding}>
                                    <IntlMessages id='shared.cuttingOrder.abbreviation' />
                                    <span>{': '}</span>
                                    <span className='font-weight-bold wrap'>
                                        {cuttingOrderNumber}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {isCuttingEnabled && rollCodes?.length && (
                        <tr>
                            <td colSpan={2}>
                                <IntlMessages id='shared.gridHeader.rollCode' />
                                <span style={inlinePadding} />
                                <span className='font-weight-bold wrap'>
                                    {rollCodes.length <= 4
                                        ? rollCodes?.join(', ')
                                        : showOthersButton
                                        ? rollCodes.slice(0, 4).join(', ')
                                        : rollCodes?.join(', ')}
                                </span>
                                {rollCodes.length > 4 && showOthersButton && (
                                    <span
                                        onClick={handleShowOthersButton}
                                        className={`${styles.showMoreButton} font-weight-bold wrap mx-1`}
                                    >
                                        <IntlMessages id='shared.showMore' />
                                    </span>
                                )}
                            </td>
                        </tr>
                    )}
                    <tr className='noBorderBottom'>
                        <td className={'pb-0'} colSpan='2'>
                            <IntlMessages id='shared.style' />
                        </td>
                    </tr>
                    <tr>
                        <td className='style_name pt-0 wrap' colSpan='2'>
                            {trimField(
                                `${styleName} - ${styleCode}`,
                                MAX_CHARS_NUM.STYLE
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className='d-flex'>
                                <div className='w-50'>
                                    <IntlMessages
                                        id={`shared.${
                                            type === 'batch' ? 'batch' : 'group'
                                        }`}
                                    />
                                    <span>{': '}</span>
                                    <span className='font-weight-bold'>
                                        {number}
                                    </span>
                                </div>
                                <span style={verticalSeparatorStyle} />
                                <div
                                    className={`w-50 ${
                                        highlight?.includes('quantity')
                                            ? 'highlight'
                                            : ''
                                    }`}
                                    style={inlinePadding}
                                >
                                    <IntlMessages id='shared.theCount' />
                                    <span>{': '}</span>
                                    <span className='font-weight-bold'>
                                        {quantity}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {batchGroup && (
                        <tr>
                            <td>
                                <IntlMessages id='shared.group' />:
                            </td>
                            <td>{batchGroup.join(', ')}</td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <IntlMessages id='shared.theColor' />
                        </td>
                        <td className='wrap'>
                            {trimField(color, MAX_CHARS_NUM.COLOR)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <IntlMessages id='shared.theSize' />
                        </td>
                        <td>{size}</td>
                    </tr>
                    {type === 'batchGroup' &&
                        (batches?.length > 4 ? (
                            <tr>
                                <td colSpan='2'>
                                    <IntlMessages id='shared.batchesCount' />
                                    <strong className='ml-2'>
                                        {batches.length}
                                    </strong>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>
                                    <IntlMessages id='shared.batch.plural' />
                                </td>
                                <td>
                                    {batches.map((b, i) => (
                                        <strong key={b}>
                                            {i === batches.length - 1
                                                ? b
                                                : `${b},`}
                                        </strong>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    {serial?.from ? (
                        <tr
                            className={`noBorderBottom ${
                                highlight?.includes('serial') ? 'highlight' : ''
                            }`}
                        >
                            <td>
                                <IntlMessages id='shared.from' />
                                &nbsp;
                                <strong>{serial.from}</strong>
                                &nbsp;
                                <IntlMessages id='shared.to' />
                                &nbsp;
                                <strong>
                                    {type === 'batch' && !serial.to
                                        ? parseInt(serial.from) +
                                          parseInt(quantity) -
                                          1
                                        : serial.to}
                                </strong>
                            </td>
                            <td
                                className={
                                    isDirectionRTL ? 'alignLeft' : 'alignRight'
                                }
                            >
                                <img
                                    src={garmentLogo}
                                    className='icon'
                                    alt='logo'
                                />
                            </td>
                        </tr>
                    ) : (
                        <tr
                            className={`noBorderBottom
						 ${type === 'batch' ? (isDirectionRTL ? 'alignLeft' : 'alignRight') : ''}`}
                        >
                            <td colSpan='2'>
                                <div
                                    className={
                                        type === 'batchGroup' ? 'cell' : ''
                                    }
                                >
                                    {type === 'batchGroup' && (
                                        <p className='noMargin'>{serial}</p>
                                    )}
                                    <img
                                        src={garmentLogo}
                                        className='icon'
                                        alt='logo'
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
