import { store } from '../../../../store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryIdleTimeEnabledRedux = () => {
    try {
        const state = store.getState();
        return state.settings.factoryIdleTime;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const companyIdleTimeEnabledRedux = () => {
    try {
        const state = store.getState();
        return state.settings.companyIdleTime;
    } catch (e) {
        return false;
    }
};
/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryIdleTimeEnabled = () => {
    try {
        return !!JSON.parse(localStorage.user_id).factoryData.idleTime;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const companyIdleTime = () => {
    try {
        return !!JSON.parse(localStorage.user_id).companyData.idleTime;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const idleTimeEnabled = () => {
    try {
        const state = store.getState();
        return state.settings.companyIdleTime && state.settings.factoryIdleTime;
    } catch (e) {
        return false;
    }
};

/** @returns {boolean}  */
export const useHasIdleTime = () =>
    useSelector(({ settings }) => {
        return settings.factoryIdleTime && settings.companyIdleTime;
    });
