import {
	HIDE_MESSAGE,
	INIT_URL,
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	SHOW_MESSAGE,
	SIGNIN_USER,
	SIGNIN_CARD,
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER,
	CARD_SIGNOUT_USER,
	SIGNOUT_USER_SUCCESS,
	SIGNUP_USER,
	SIGNUP_USER_SUCCESS,
	SIGNIN_USER_STATIONS,
	SET_SOCKET_ID,
	SET_USER_PERMISSION,
	SET_TOAST,HOLD_TOAST,
	UPDATE_USER_PREFERENCES,
	IMPERSONATE_USER,
	SET_IMPERSONATION_DATA
} from 'constants/ActionTypes';

export const holdToast = () => {
	return {
		type: HOLD_TOAST
	};
};
export const setToast = (state) => {
	return {
		type: SET_TOAST,
		payload: state
	};
};
export const userSignUp = (user) => {
	return {
		type: SIGNUP_USER,
		payload: user
	};
};
export const userSignIn = (user) => {
	return {
		type: SIGNIN_USER,
		payload: user
	};
};

export const cardSignIn = (user) => {
	return {
		type: SIGNIN_CARD,
		payload: user
	};
};

export const userSignOut = () => {
	return {
		type: SIGNOUT_USER
	};
};

export const cardSignOut = () => {
	return {
		type: CARD_SIGNOUT_USER
	};
};

export const userSignUpSuccess = (authUser) => {
	return {
		type: SIGNUP_USER_SUCCESS,
		payload: authUser
	};
};

export const userSignInSuccess = (authUser) => {
	return {
		type: SIGNIN_USER_SUCCESS,
		payload: authUser
	};
};
export const userSignOutSuccess = () => {
	return {
		type: SIGNOUT_USER_SUCCESS,
	};
};

export const showAuthMessage = (message) => {
	return {
		type: SHOW_MESSAGE,
		payload: message
	};
};


export const setUserPermissions = (user) => {
	return {
		type: SET_USER_PERMISSION,
		payload: user
	};
};



export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const showAuthLoader = () => {
	return {
		type: ON_SHOW_LOADER,
	};
};

export const hideMessage = () => {
	return {
		type: HIDE_MESSAGE,
	};
};

export const hideAuthLoader = () => {
	return {
		type: ON_HIDE_LOADER,
	};
};

export const userSignInStations = (user) => ({type:SIGNIN_USER_STATIONS,payload:user})


export const setSocketId = (id) => ({type:SET_SOCKET_ID,payload:id})

export const updateUserPreferences = preferences => ({ type: UPDATE_USER_PREFERENCES, payload: preferences })

export const impersonateUser = impersonatedId =>
	({ type: IMPERSONATE_USER, payload: impersonatedId})

export const setImpersonationData = impersonationData =>
	({ type: SET_IMPERSONATION_DATA, payload: impersonationData })
