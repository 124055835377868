import { store } from '../../../../store';
import { useSelector } from 'react-redux';

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const factoryCuttingEnabled = () => {
    try {
        return !!JSON.parse(localStorage.user_id)?.factoryData
            ?.isCuttingEnabled;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const companyCutting = () => {
    try {
        return !!JSON.parse(localStorage.user_id).companyData.isCuttingEnabled;
    } catch (e) {
        return false;
    }
};

/**
 *  @description LOW LEVEL API DO NOT USE
 *  @returns {boolean}
 */
export const cuttingEnabled = () => {
    try {
        const state = store.getState();
        return state.settings.companyCutting && state.settings.factoryCutting;
    } catch (e) {
        return false;
    }
};

/** @returns {boolean}  */
export const useHasCutting = () =>
    useSelector(({ settings }) => {
        return settings.factoryCutting && settings.companyCutting;
    });
