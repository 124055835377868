import axiosInstance from './axiosInstance';
import devicesAxiosInstance from './devicesAxiosInstance';

class AuthAPI {
    signIn = (email, password) =>
        axiosInstance.post('login', { email, password });

    signInCard = (companySlug, rfid) =>
        axiosInstance.post('loginWithCard', { rfid, companySlug });

    signInStations = (
        company_slug,
        factory_id,
        stationType,
        stationId,
        email,
        password
    ) => {
        const type =
            stationType === 'cs' ? 'control-station' : 'quality-station';
        return axiosInstance.post(
            `${company_slug}/${factory_id}/${type}/login-from-web/${stationId}`,
            {
                email,
                password,
            }
        );
    };

    getOneFactory = (token, company_slug) => {
        axiosInstance.defaults.headers.token = token;
        devicesAxiosInstance.defaults.headers.token = token;
        return axiosInstance.get(`${company_slug}/user_factories`);
    };
    getCountries = (token) => {
        axiosInstance.defaults.headers.token = token;
        devicesAxiosInstance.defaults.headers.token = token;
        return axiosInstance.get('/get_countries');
    };

    impersonateUser = (impersonatedId) => {
        return axiosInstance.post('auth/impersonate', { impersonatedId });
    };

    getCompanySlug = () => axiosInstance.get('company_slug');
}

export default AuthAPI;
