import {GET_NOTIFICATIONS, 
    GET_NOTIFICATIONS_SUCCESS, 
    PROCESS_NOTIFICATION, 
    ADD_NOTIFICATION, 
    UPDATE_LAST_READ, 
    UPDATE_READ_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATIONS
} from 'constants/ActionTypes';

export const getNotifications = slice => {
	return {
        type: GET_NOTIFICATIONS,
        payload:slice
	};
};

export const getNotificationsSuccess = notifications => {
	return {
		type: GET_NOTIFICATIONS_SUCCESS,
		payload: notifications
	};
};

export const processNotification = notification => {
    return {
        type: PROCESS_NOTIFICATION,
        payload: notification
    };
};

export const addNotification = notification => {
    return {
        type: ADD_NOTIFICATION,
        payload: notification
    };
};

export const updateLastRead = date => {
    return {
        type: UPDATE_LAST_READ,
        payload: date
    };
};

export const updateReadNotifications = id => {
    return {
        type: UPDATE_READ_NOTIFICATIONS,
        payload: id
    };
};

export const deleteNotification = id => {
    return {
        type: DELETE_NOTIFICATION,
        payload: id
    };
};

export const deleteNotifications = () => {
    return {
        type: DELETE_NOTIFICATIONS
    };
};