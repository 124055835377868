import axios from 'axios';
import { GET_DEVICES_BASE_URL } from '../../devtools/companyUrlSwitcher/utils';
import { onFulfilled, onRejected } from './axiosInstancesInterceptors';

const baseURL = GET_DEVICES_BASE_URL();

const devicesAxiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10000,
});

devicesAxiosInstance.interceptors.response.use(onFulfilled, onRejected);

export default devicesAxiosInstance;
