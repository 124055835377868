import axiosInstance from './axiosInstance';

class NotificationAPI {
    getNotifications = (slice) =>
        axiosInstance.get(`notification/getNotificationsByUserId/${slice}`);

    updateLastReadDate = () => axiosInstance.put('notification/updateLastRead');

    deleteNotification = (notficationId) =>
        axiosInstance.delete(
            `notification/deleteNotification/${notficationId}`
        );

    readNotification = (notificationId) =>
        axiosInstance.put(`notification/readNotification/${notificationId}`);
}

export default NotificationAPI;
