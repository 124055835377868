import scan from 'assets/images/scan_tag.gif';
import React from 'react';
import '../../styles/styles.scss';

// Image used when a page includes scanning functionality
const ScanningImage = ({ imageStyle }) => {
    return (
        <div className='d-flex m-3'>
            <img
                style={imageStyle ?? {}}
                className='center'
                src={scan}
                alt='scan batch illustration'
                width='50%'
            />
        </div>
    );
};

export default ScanningImage;
